import React, { useEffect } from "react";
import styles from "./ConfirmSu.module.scss";
import useSWRMutation from "swr/mutation";
import { capitalize, useQuery } from "../../../libs/common";
import {
  parseClientError,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../libs/api/Endpoints";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Grid } from "react-loader-spinner";
import { generateResult } from "../../../global components/ResultModal/ResultModal";

function ConfirmSu() {
  const query = useQuery().get("email");
  const {
    reset,
    formState: { isSubmitting },
  } = useForm({ shouldFocusError: false });
  const navigate = useNavigate();

  const { trigger: confirmSubscription, isMutating } = useSWRMutation(
    "api/newsletter/confirm-subscription",
    swrMutationFetcher
  );

  useEffect(() => {
    if (!query) return;

    const subscribeUser = async () => {
      try {
        const urlEncodedData = new URLSearchParams();
        urlEncodedData.append("email", query);

        const fetcherArg: SwrMutationFetcherArgType = {
          requestOption: {
            method: "POST",
            body: urlEncodedData,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          },
        };

        const result = await confirmSubscription(fetcherArg);
        generateResult("success", capitalize(result.message));
        navigate("/");
        reset();
      } catch (error) {
        const parsedError = parseClientError(error);
        generateResult(
          "error",
          capitalize(parsedError.message) || "An unexpected error occurred."
        );
      }
    };

    subscribeUser();
  }, [query, confirmSubscription, navigate, reset]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {(isMutating || isSubmitting) ? (
          <Grid
            visible={true}
            height="80"
            width="80"
            color="#d52b1e"
            ariaLabel="grid-loading"
            radius="12.5"
          />
        ) : (
          <p>Subscription Confirmed!</p>
        )}
      </div>
    </div>
  );
}

export default ConfirmSu;
