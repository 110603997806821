import { useRef, useEffect } from "react";

function useDocumentMetadata(title: string, description?: string, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  const defaultDescription = useRef(
    document.querySelector("meta[name='description']")?.getAttribute("content") || ""
  );

  useEffect(() => {
    document.title = title;

    if (description) {
      let metaDescription = document.querySelector("meta[name='description']");
      if (!metaDescription) {
        metaDescription = document.createElement("meta");
        metaDescription.setAttribute("name", "description");
        document.head.appendChild(metaDescription);
      }
      metaDescription.setAttribute("content", description);
    }
  }, [title, description]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;

        let metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
          metaDescription.setAttribute("content", defaultDescription.current);
        }
      }
    },
    [prevailOnUnmount]
  );
}

export default useDocumentMetadata;
