import React from "react";
function Twitter() {
  return (
    <div>
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1328 16.8478L11.264 7.62469L17.0549 1.25438C17.1859 1.10674 17.2534 0.913434 17.2428 0.716365C17.2322 0.519295 17.1444 0.334346 16.9983 0.201615C16.8523 0.0688843 16.6598 -0.00092985 16.4626 0.00730857C16.2654 0.015547 16.0794 0.101174 15.9449 0.245625L10.4287 6.31312L6.63276 0.347813C6.56507 0.24127 6.47158 0.153535 6.36097 0.0927355C6.25035 0.0319361 6.12617 3.91221e-05 5.99994 8.87202e-08H1.49994C1.36547 -6.53095e-05 1.23345 0.0360256 1.11771 0.104494C1.00198 0.172962 0.906774 0.271288 0.842079 0.389177C0.777384 0.507066 0.745575 0.64018 0.749983 0.774582C0.75439 0.908984 0.794852 1.03973 0.867132 1.15313L6.73588 10.3753L0.944945 16.7503C0.87732 16.823 0.824785 16.9083 0.790386 17.0014C0.755987 17.0945 0.740407 17.1935 0.74455 17.2927C0.748693 17.3918 0.772477 17.4892 0.814522 17.5791C0.856566 17.669 0.916036 17.7496 0.989485 17.8164C1.06293 17.8831 1.1489 17.9346 1.2424 17.9679C1.3359 18.0012 1.43508 18.0156 1.53419 18.0103C1.63329 18.0049 1.73035 17.98 1.81974 17.9369C1.90914 17.8937 1.98908 17.8333 2.05494 17.7591L7.5712 11.6916L11.3671 17.6569C11.4354 17.7625 11.5291 17.8494 11.6397 17.9093C11.7503 17.9693 11.8742 18.0005 11.9999 18H16.4999C16.6343 18 16.7661 17.9638 16.8817 17.8954C16.9973 17.827 17.0924 17.7288 17.1571 17.611C17.2218 17.4933 17.2536 17.3604 17.2493 17.2261C17.2451 17.0918 17.2048 16.9612 17.1328 16.8478ZM12.4115 16.5L2.86588 1.5H5.58463L15.134 16.5H12.4115Z"
          fill="#F9F9F9"
        />
      </svg>
    </div>
  );
}

export default Twitter;
