import { UsergroupAddOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useCallback, useState } from "react";
import s from "./Recipients.module.scss";
import ReusableDrawer from "../../../../global components/Drawer/ReuseableDrawer";
import ContactsTab from "./ContactsTab";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import clsx from "clsx";
import ConfirmationModal from "../../../../global components/CofirmationModal/ConfirmationModal";
import { useMediaQuery } from "react-responsive";
import ReusableModal from "../../../../global components/Modal/Modal";
type Props = {
  handleContact: (contactIds: string[]) => void;
  handleGroup: (groupIds: Array<{ name: string; id: string }>) => void;
};
function Recipients({ handleContact,handleGroup }: Props) {
  const [groups, setGroups] = useState<string[]>([]);
  const [contacts, setContacts] = useState<string[]>([]);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  const handleDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };
  const handleResult = () => {
    if (!contacts.length && !groups.length) {
      generateResult("error", "No recipients selected");
    } else {
      generateResult("success", "Recipients added successfully");
      handleDrawer();
    }
  };
  const handleConfirmationModal = () => {
    setOpen((prev) => !prev);
  };
   const handleContacts = useCallback(
      (contactsIds: string[]) => {
        setContacts(contactsIds)
        console.log(contactsIds)
        handleContact(contactsIds);
      },
      [handleContact]
    ); // Memoize handleContacts function
  
    const handleGroups = useCallback(
      (groupsIds: Array<{ name: string; id: string }>) => {
        setGroups(groupsIds.map((group) => group.id));
        handleGroup(groupsIds);
      },
      [handleGroup]
    );

  return (
    <>
      <ConfirmationModal
        handleCancel={() => handleDrawer()}
        open={open}
        handleConfirmationModal={handleConfirmationModal}
      />
      <ReusableModal
        title="Select Recipients"
        footer={
          <div className={s.add_to_group_footer}>
            {/* //<Divider /> */}
            <div className={s.addBtns}>
              <Button
                className={clsx(s.button, s.cancelBtn)}
                onClick={handleConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                onClick={handleResult}
                className={clsx(s.button, s.addContactBtn)}
              >
                Done
              </Button>
            </div>
          </div>
        }
        isModalOpen={isMobile && openDrawer}
        handleModal={handleDrawer}
      >
        <ContactsTab handleContact={handleContacts} handleGroup={handleGroups} />
      </ReusableModal>
      <ReusableDrawer
        title="Select Recipients"
        footer={
          <div className={s.add_to_group_footer}>
            {/* //<Divider /> */}
            <div className={s.addBtns}>
              <Button
                className={clsx(s.button, s.cancelBtn)}
                onClick={handleConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                onClick={handleResult}
                className={clsx(s.button, s.addContactBtn)}
              >
                Done
              </Button>
            </div>
          </div>
        }
        Open={openDrawer}
        handleOpen={handleDrawer}
      >
        <ContactsTab  handleContact={handleContacts} handleGroup={handleGroups} />
      </ReusableDrawer>
      <div className={s.wrapper}>
        <Button
          onClick={handleDrawer}
          className={s.btn}
          icon={<UsergroupAddOutlined />}
          iconPosition={"start"}
        >
          Recipients
        </Button>
        <p className={s.helperText}>
          Add the recipients you would like to send your message to
        </p>
      </div>
    </>
  );
}

export default Recipients;
