import { useLocation, useNavigate } from "react-router-dom";
import { UseAuthToken } from "../../../../libs/api/userSession";
import WaveSurfer from "wavesurfer.js";
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../libs/api/Endpoints";
import s from "./Review.module.scss";
import clsx from "clsx";
import { Button } from "antd";
import useSWRMutation from "swr/mutation";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import { useDraft } from "../../../../global components/Context/DraftContext";
import { PaginatedScheduledMessage } from "../../../../libs/Utils/Types/Message";
import { capitalize } from "../../../../libs/common";
import { SuccessDataType } from "../../../../libs/api/types";
// Define the shape of the state
interface EmailState {
  msgTitle?: string;
  emailSubject?: string;
  contactIds: string[];
  contactGroupIds: string[];
  contactGroupNames: string[];
  groupId: string | undefined;

  content?: string;
  schedule?: "";
  from?: string;
  type: string;
  audioURL: string;
  msgId: string;
  medias: { publicId: any; secureUrl: any }[] | undefined;
  replyTo?: string;
  reset?: () => void;
}

function Review() {
  // Use the location hook without generics
  const location = useLocation();
  const { cancelDraft } = useDraft();

  // Destructure the state with default values to avoid issues if the state is undefined
  const {
    msgTitle = "Untitled",
    emailSubject = "No subject",
    contactIds = [],
    contactGroupIds = [],
    content = "",
    schedule = "",
    from = "",
    type = "",
    audioURL = "",
    msgId = "",
    replyTo = "",
    medias = undefined,
    contactGroupNames = [],
    groupId = undefined,

    reset = () => {},
  } = (location.state as EmailState) || {};
  const token = UseAuthToken();
  const [searchValue /*setSearchValue*/] = useState("");
  const [currentPage /*setCurrentPage*/] = useState(1);
  const [pageItems /*setPageItems*/] = useState(10);
  const [filter /*setFilter*/] = useState<string>("");

  const { mutate: mutateSchedule } = useSWR<
    PaginatedScheduledMessage,
    any,
    any
  >(
    token
      ? [
          "api/messages",
          token,
          {
            page: currentPage,
            limit: pageItems,
            search: searchValue,
            status: "scheduled",
            deliveryStatus: filter,
          },
        ]
      : null,
    swrFetcher,
    {
      revalidateOnFocus: true, // Refetch when the window regains focus
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );
  const { trigger: submitMsg, isMutating } = useSWRMutation(
    `api/messages/${msgId ? msgId : ""}`,
    swrMutationFetcher
  );
  const navigate = useNavigate();
  function htmlToPlainText(htmlString: string): string {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    const textContent: string[] = [];

    // Process child nodes to extract only text content
    Array.from(tempElement.childNodes).forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent?.trim();
        if (text) {
          textContent.push(text);
        }
      } else if (
        node.nodeType === Node.ELEMENT_NODE &&
        (node as HTMLElement).tagName !== "IMG"
      ) {
        const elementText = (node as HTMLElement).textContent?.trim();
        if (elementText) {
          textContent.push(elementText);
        }
      }
    });

    // Concatenate text with full stops
    const formattedText =
      textContent.join(". ") + (textContent.length ? "." : "");

    return formattedText.trim();
  }

  const sendMsg = async () => {
    try {
      const formData = new FormData();
      if (msgTitle) formData.append("title", msgTitle);
      formData.append("type", type);

      if (emailSubject) formData.append("subject", emailSubject);
      if (replyTo) formData.append("replyTo", replyTo);
      if (content && type !== "email" && type !== "mms") {
        formData.append("body", content);
      } else if (content && type === "mms") {
        formData.append("body", htmlToPlainText(content));
      } else if (content && type === "email") {
        formData.append("body", content);
      }
      formData.append("isDraft", "false");
      if (medias && medias.length > 0) {
        medias.forEach((image, index) => {
          formData.append(`medias[${index}][publicId]`, image.publicId);
          formData.append(`medias[${index}][secureUrl]`, image.secureUrl);
        });
      }
      if (audioURL) formData.append("body", audioURL);
      if (from) formData.append("senderName", from);
      if (contactGroupIds.length)
        contactGroupIds.forEach((id) => {
          formData.append("contactGroupIds[]", id);
        });
      if (groupId) {
        formData.append("contactGroupIds[]", groupId);
      }
      if (contactIds.length)
        contactIds.forEach((id) => {
          formData.append("contactIds[]", id);
        });
      if (schedule) {
        formData.append("scheduledFor", schedule);
      }
      const fetcherArg: SwrMutationFetcherArgType = {
        token,
        requestOption: {
          method: msgId ? "PATCH" : "POST",
          body: formData,
        },
      };
      cancelDraft();
      const result: SuccessDataType<any> = await submitMsg(fetcherArg);
      reset && reset();
      generateResult(
        "success",
        schedule ? "Message scheduled" : "Message sent"
      );
      navigate("/dashboard/Message");
      mutateSchedule();
    } catch (error) {
      const e = parseClientError(error);

      generateResult(
        "error",
        capitalize(e.message) || "An unexpected error occurred."
      );
    }
  };
  const waveformRef = useRef(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  function handleNavigate() {
    if (type === "email") navigate(`/dashboard/Message/email?msgId=${msgId}`);
    if (type === "sms") navigate(`/dashboard/Message/sms?msgId=${msgId}`);
    if (type === "voice")
      navigate(`/dashboard/Message/voice-message?msgId=${msgId}`);
  }
  useEffect(() => {
    if (audioURL && waveformRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "violet",
        progressColor: "purple",
        height: 100,
      });
      wavesurferRef.current.load(audioURL);

      return () => {
        wavesurferRef.current?.destroy();
      };
    }
  }, [audioURL]);

  return (
    <div className={s.wrapper}>
      <div className={s.welcomeTab}>
        <div className={s.titles}>
          <h1 className={s.title}>Review</h1>
          <h3 className={s.subtitle}>
            Take a final look at your message before sending it out to ensure
            accuracy
          </h3>
        </div>
        <div className={s.contactBtnGroups}>
          {msgId && (
            <Button onClick={handleNavigate} className={clsx(s.editBtn)}>
              Edit
            </Button>
          )}
          <Button
            loading={isMutating}
            onClick={sendMsg}
            className={clsx(s.createBtn)}
          >
            {schedule ? "Schedule now" : " Send now"}
          </Button>
        </div>
      </div>
      <div className={s.msg}>
        <div className={s.titleWrapper}>
          <div className={s.msgTitleWrapper}>
            <h3>{msgTitle}</h3>
          </div>

          <table className={s.msgTitle}>
            {type === "email" && (
              <tr>
                <td className={s.emailTitles}>Email subject</td>
                <td className={s.subtitles}>
                  <p> {emailSubject}</p>
                </td>
              </tr>
            )}
            <tr>
              <td className={s.emailTitles}>Name of group</td>
              <td className={s.subtitles}>
                {contactGroupNames.join(", ") || "N/A"}
              </td>
            </tr>
            <tr>
              <td className={s.emailTitles}>Number of contacts</td>
              <td className={s.subtitles}>{contactIds.length}</td>
            </tr>
          </table>
        </div>
        <div>
          <div className={s.msgBodyWrapper}>
            <h3>Message</h3>
          </div>

          <div className={s.msgBody}>
            {audioURL ? (
              <div className={s.audio}>
                <audio src={audioURL} controls />
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            )}
          </div>
          {/* Render rich text */}
        </div>
      </div>
      <div className={s.contactBtnGroupsMobile}>
        {msgId && (
          <Button onClick={handleNavigate} className={clsx(s.editBtn)}>
            Edit
          </Button>
        )}
        <Button
          loading={isMutating}
          onClick={sendMsg}
          className={clsx(s.createBtn)}
        >
          {schedule ? "Schedule now" : "Send now"}
        </Button>
      </div>
    </div>
  );
}

export default Review;
