import React from "react";
import { IconsType } from "../../libs/Utils/types";
type ElipsesProp= IconsType &{
    onClick?:()=>void
}
function Elipses({height,width,fill,onClick}:ElipsesProp) {
  return (
    <div  onClick={onClick}>
      <svg
         width={width}
         height={height}
        viewBox="0 0 18 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM16 0C14.9 0 14 0.9 14 2C14 3.1 14.9 4 16 4C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0ZM9 0C7.9 0 7 0.9 7 2C7 3.1 7.9 4 9 4C10.1 4 11 3.1 11 2C11 0.9 10.1 0 9 0Z"
          fill={fill||"#5F5666"}
        />
      </svg>
    </div>
  );
}

export default Elipses;
