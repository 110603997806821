import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import s from "./App.module.scss";
import clsx from "clsx";
import Sidebar from "./Local components/Sidebar/Sidebar";
import Header from "./Local components/Header";
import useSWR from "swr";
import { swrFetcher } from "../../libs/api/Endpoints";
import { UseAuthToken } from "../../libs/api/userSession";
import { User } from "../../libs/redux/auth/signinSlice";
import useDocumentMetadata from "../../global components/PageTitle";

function Dashboard() {
  const token = UseAuthToken();
  const documentTitle = "Cloudivi | Dashboard";
  const documentDescription =
    "Create smart keywords send automated messages to your audience with Cloudivi.";
  useDocumentMetadata(documentTitle, documentDescription);
  
  // Helper function to get cached user data from localStorage
  const getCachedUser = () => {
    const cachedUser = localStorage.getItem("user_data");
    return cachedUser ? JSON.parse(cachedUser) : null;
  };

  // Using the cached user data as the initial value in SWR
  const { data } = useSWR<User, any, any>(
    token ? [`api/users/me`, token] : null, // Only fetch if token exists
    swrFetcher,
    {
      revalidateOnFocus: true, // Refetch when the window regains focus
      shouldRetryOnError: false, // Disable retrying on errors
      fallbackData: getCachedUser(), // Use cached data if available
    }
  );

  // Cache the user data in localStorage whenever new data is fetched
  useEffect(() => {
    if (data) {
      localStorage.setItem("user_data", JSON.stringify(data));
    }
  }, [data]);

  return (
    <div className={clsx(s.wrapper)}>
      <div className={clsx(s.sidebar)}>
        <Sidebar />
      </div>
      <div className={s.headers}>
        <Header
          avatar={data?.avatar}
          email={data?.email}
          firstName={data?.firstName}
          lastName={data?.lastName}
        />
      </div>
      <div className={clsx(s.main)}>
        <Outlet />
      </div>
      <div className={s.disclaimer}>
        <p>
          Cloudivi by &nbsp;
          <a
            href="https://vomoz.com"
            rel="noreferrer"
            target="_blank"
            className={s.link}
          >
            Vomoz
          </a>
        </p>
      </div>
    </div>
  );
}

export default Dashboard;
