import React, { useState } from "react";
import s from "./ContactDetails.module.scss";
import clsx from "clsx";
import { capitalize, useQuery } from "../../../../../libs/common";
import useSWR from "swr";
import { UseAuthToken } from "../../../../../libs/api/userSession";
import {
  parseClientError,
  swrFetcher,
} from "../../../../../libs/api/Endpoints";
import Edit from "../../../../../global components/Icons/Edit/Edit";
import ReusableDrawer from "../../../../../global components/Drawer/ReuseableDrawer";
import { SingleContactResponse } from "../../../../../libs/Utils/Types/singContact";
import EditContactForm from "../../EditContactForm";
import ContactAvatar from "../../../../../global components/Icons/ContactAvatar/ContactAvatar";
import ReusableModal from "../../../../../global components/Modal/Modal";
import { message, Spin } from "antd";
import { useToggle } from "../../../../../global components/Context/NavbarContext";
import { useMediaQuery } from "react-responsive";
function ContactDetails() {
  const query = useQuery();
  const [open, setOpen] = useState<boolean>(false);
  const { isTrue, toggle } = useToggle();
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  const contactID = query.get("contactID");
  const token = UseAuthToken();
  const { data, isLoading, mutate, error } = useSWR<
    SingleContactResponse,
    any,
    any
  >(
    token ? [`api/contacts/${contactID}`, token] : null,
    swrFetcher,

    {
      revalidateOnFocus: true, // Refetch when the window regains focus
      // Refetch every 10 seconds (10000 ms)
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );
  function handleDrawer() {
    if (!isTrue) {
      toggle();
    }
    setOpen((prev) => !prev);
  }
  if (error) {
    const e = parseClientError(error);
    message.error(capitalize(e.message) || "Something went wrong");
  }
  return (
    <>
      <ReusableDrawer
        title="Edit contact"
        Open={!isMobile && open}
        handleOpen={handleDrawer}
      >
        <EditContactForm
          mutate={mutate}
          handleOpen={handleDrawer}
          contactID={contactID || null}
        />
      </ReusableDrawer>
      <ReusableModal
        handleModal={handleDrawer}
        isModalOpen={isMobile && open}
        title="Edit contact"
        footer={null}
        centered
      >
        <EditContactForm
          mutate={mutate}
          handleOpen={handleDrawer}
          contactID={contactID || null}
        />
      </ReusableModal>
      <div className={s.wrapper}>
        <div className={s.mainWrapper}>
          <section className={s.header}>
            <h2 className={s.title}>Contact details</h2>
            <button
              className={clsx(s.button, s.editBtn)}
              onClick={handleDrawer}
            >
              <Edit height={16} width={16} fill="#FFFFFF" />
              Edit details
            </button>
          </section>
          {isLoading || error ? (
            <div className={s.spin}>
              <Spin />
            </div>
          ) : (
            <section className={s.contactDetails}>
              <div className={s.profileDetails}>
                {!data?.contact.firstName || !data?.contact.lastName ? (
                  <ContactAvatar width={80} height={80} />
                ) : data.contact.firstName && data.contact.lastName ? (
                  <div className={s.profilePic}>
                    <h1>
                      {data?.contact.firstName[0] + data?.contact.lastName[0]}
                    </h1>
                  </div>
                ) : (
                  <ContactAvatar width={80} height={80} />
                )}
                <span className={s.name}>
                  <h2>
                    {data && (data?.contact.firstName || data.contact.lastName)
                      ? (data?.contact.firstName
                          ? data?.contact.firstName.charAt(0).toUpperCase() +
                            data?.contact.firstName.slice(1)
                          : "") +
                        " " +
                        (data?.contact.lastName
                          ? data?.contact.lastName.charAt(0).toUpperCase() +
                            data?.contact.lastName.slice(1)
                          : "")
                      : "N/A"}
                  </h2>
                </span>
              </div>
              <table className={s.table}>
                <tr>
                  <td className={s.tableLabel}>Email address</td>
                  <td className={clsx(s.tableLabel, s.tableData)}>
                    {data?.contact.email
                      ? data?.contact.email
                      : "No email found"}
                  </td>
                </tr>
                <tr>
                  <td className={s.tableLabel}>Phone number</td>
                  <td className={clsx(s.tableLabel, s.tableData)}>
                    {data?.contact.phoneNo
                      ? data?.contact.phoneNo
                      : "No phone number found"}
                  </td>
                </tr>
                <tr>
                  <td className={s.tableLabel}>Assigned groups</td>
                  <td className={clsx(s.tableLabel, s.tableData)}>
                    <span className={s.groupNames}>
                      {data?.groups && data?.groups.length > 0 ? (
                        data?.groups.map((group) => (
                          <p className={s.groupName}>{group.name}</p>
                        ))
                      ) : (
                        <p>No groups found</p>
                      )}
                    </span>
                  </td>
                </tr>
              </table>
            </section>
          )}
        </div>
        <button className={clsx(s.editBtn)} onClick={handleDrawer}>
          <Edit height={16} width={16} fill="#FFFFFF" />
          Edit details
        </button>
      </div>
    </>
  );
}

export default ContactDetails;
