import React, { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import s from "./Homepage.module.scss";
import Header from "../LandingPage/Header/Header";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger); // ✅ Register ScrollTrigger

function HomePage() {
  const location = useLocation();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const heroSection = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!heroSection.current || !headerRef.current) return;

    gsap.to(headerRef.current, {
      background: "black", 
      ease: "power2.out",
      scrollTrigger: {
        trigger: heroSection.current,
        start: "top 120px",
        scrub: 0.2,
        onEnter: () => console.log("Scrolled past hero section"),
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill()); 
    };
  }, []);

  return (
    <div>
      {(location.pathname === "/") && (
        <TawkMessengerReact
          propertyId="6790eb403a8427326072f627"
          widgetId="1ii71dtqp"
        />
      )}
      <div ref={headerRef} className={s.header}>
        <Header />
      </div>
      <div ref={heroSection} className={s.heroContent}>
        <Outlet />
      </div>
    </div>
  );
}

export default HomePage;
