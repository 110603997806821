import { UseAuthToken } from "../../../../../libs/api/userSession";
import useSWR from "swr";
import { swrFetcher } from "../../../../../libs/api/Endpoints";
import s from "./Message.module.scss";
import { Skeleton } from "antd";
import { useQuery } from "../../../../../libs/common";
import { PaginatedContacts } from "../../../../../libs/Utils/Types/Contact";
import SelectField from "../../../../../global components/Forms/SelectField/SelectField";
import { useState } from "react";
import ContactAvatar from "../../../../../global components/Icons/ContactAvatar/ContactAvatar";
import Contacts from "./Contacts";
import { useNavigate } from "react-router-dom";
// Define the shape of the state
type Email = {
  title: string;
  from: string;
  subject: string;
  body: string;
  type: string;
  scheduledFor: string; // ISO 8601 format
  sentAt: string; // ISO 8601 format
  user: string;
  createdAt: string; // ISO 8601 format
  updatedAt: string; // ISO 8601 format
  totalCreditsUsed: number;
  deliveryRate: number;
  totalRecipients: number;
  nameOfContactGroups: string;
  status: string;
  id: string;
  medias: { publicId: string; secureUrl: string; id: string; _id: string }[];
};

function MessageDetails() {
  const query = useQuery();
  const msgId = query.get("msgId");
  const navigate = useNavigate();
  const token = UseAuthToken();
  // Destructure the state with default values to avoid issues if the state is undefined

  const {
    data: emailDetails,
    isLoading,
    error,
  } = useSWR<Email, any, any>(
    token ? [`api/messages/${msgId}`, token] : null,
    swrFetcher,
    {
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );
  function isValidURL(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }
  function convertResponseToMMS(response: {
    body: string;
    medias: Array<{
      secureUrl: string;
      publicId: string;
      id: string;
      _id: string;
    }>;
  }): string {
    // Extract the text body
    let htmlString = response.body;

    // If there are media items, convert them to <img> tags
    if (response.medias && response.medias.length > 0) {
      response.medias.forEach((media) => {
        const imgTag = `<img src="${media.secureUrl}" alt="image" />`;
        // Append each image below the body text
        htmlString = `${imgTag}<br />` + htmlString;
      });
    }

    return htmlString;
  }

  function plainTextToHTML(plainText: string): string {
    // Initialize a variable to hold the result HTML
    let htmlString = plainText;

    // Match the image URLs, accounting for query parameters, fragments, etc.
    const imageUrls = plainText.match(
      /https?:\/\/[^\s]+(?:\.(?:jpg|jpeg|png|gif|bmp|svg))(?:[^\s]*)/gi
    );

    if (imageUrls) {
      // For each image URL, wrap it in an <img> tag
      imageUrls.forEach((url) => {
        // Replace each image URL with the corresponding <img> tag
        const imgTag = `<img src="${url}" alt="image" />`;
        htmlString = htmlString.replace(url, imgTag);
      });
    }

    return htmlString;
  }
  return (
    <div className={s.mainWrapper}>
      <div className={s.msgHeader}>
        {isLoading || error ? (
          <Skeleton.Node
            active={isLoading || error}
            style={{ width: "100px", height: "40px" }}
          />
        ) : (
          <h2 className={s.title}>{emailDetails?.title}</h2>
        )}
        <button
          onClick={() =>
            navigate("contact", {
              state: {
                msgID: msgId,
              },
            })
          }
          className={s.createBtn}
        >
          View contacts
        </button>
      </div>
      <div className={s.main}>
        <div className={s.wrapper}>
          <div className={s.msg}>
            {isLoading || error ? (
              <Skeleton.Button
                active={true}
                size={"default"}
                shape={"square"}
                block={true}
                style={{ height: "161px" }}
              />
            ) : (
              <table className={s.msgTitle}>
                {emailDetails?.subject && (
                  <tr>
                    <td className={s.emailTitles}>Email subject</td>
                    <td className={s.subtitles}>
                      <p>{emailDetails?.subject}</p>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={s.emailTitles}>Contact group</td>
                  <td className={s.subtitles}>
                    {emailDetails?.nameOfContactGroups}
                  </td>
                </tr>
                <tr>
                  <td className={s.emailTitles}>Number of contacts</td>
                  <td className={s.subtitles}>
                    {emailDetails?.totalRecipients}
                  </td>
                </tr>
              </table>
            )}

            <div>
              <div className={s.msgBodyWrapper}>
                <h3>Message</h3>
              </div>
              {isLoading || error || !emailDetails?.body ? (
                <Skeleton.Button
                  active={true}
                  size={"default"}
                  shape={"square"}
                  block={true}
                  style={{ height: "449px" }}
                />
              ) : (
                <div className={s.msgBody}>
                  {(emailDetails.type === "voice" || emailDetails.type === "voice_call") &&
                  isValidURL(emailDetails.body) ? (
                    <div className={s.audio}>
                      <audio src={emailDetails.body} controls />
                    </div>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                        emailDetails.type !== "email" &&
                        emailDetails.medias.length > 0 &&
                        emailDetails.medias[0].secureUrl.includes("cloudinary")
                          ? convertResponseToMMS({
                              body: emailDetails.body,
                              medias: emailDetails.medias,
                            })
                          : emailDetails.type === "email"
                          ? emailDetails.body
                          : plainTextToHTML(emailDetails.body),
                      }}
                    ></div>
                  )}
                </div>
              )}
              {/* Render rich text */}
            </div>
          </div>
        </div>
        <div className={s.contacts}>
          <Contacts msgId={msgId} />
        </div>
      </div>
    </div>
  );
}
export default MessageDetails;
