import React, { useState, useEffect } from "react";
import SearchInput from "../Forms/SearchInput/SearchInput";
import { CustomTag } from "../../libs/Utils/Types/Tags";
import CheckboxList from "../CheckBox/CheckBox";
import { Button,  Spin } from "antd";
import clsx from "clsx";
import s from "./AddToGroup.module.scss";
import { UseAuthToken } from "../../libs/api/userSession";
import useSWR from "swr";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../libs/api/Endpoints";
import { SingleContactResponse } from "../../libs/Utils/Types/singContact";
import { PaginatedContactGroups } from "../../libs/Utils/Types/ContactGroup";
import Tags from "../Tags/Tags";
import useSWRMutation from "swr/mutation";
import { generateResult } from "../ResultModal/ResultModal";
import { capitalize } from "../../libs/common";
import { PaginatedContacts } from "../../libs/Utils/Types/Contact";
import ConfirmationModal from "../CofirmationModal/ConfirmationModal";
import Unauthorised from "../Unauthorised";
import { SuccessDataType } from "../../libs/api/types";
import Paginate from "../Paginate/Pagination";
type AddToGroupProp = {
  handleOpen?: () => void;
  contactID?: string;
  ids?: string[];
  handleDeselect?: (value: boolean) => void;
  messages?: boolean;
  handleGroups?: (groupIds: Array<{name:string, id:string}>) => void;
};

const AddToGroup: React.FC<AddToGroupProp> = ({
  handleOpen,
  contactID,
  ids,
  messages = false,
  handleDeselect,
  handleGroups,
}) => {
  const token = UseAuthToken();
  const [open, setOpen] = useState<boolean>(false);

  const { data, isLoading, error } = useSWR<SingleContactResponse, any, any>(
    token && !messages && contactID ? [`api/contacts/${contactID}`, token] : null,
    swrFetcher,
    {
      revalidateOnFocus: true,

      shouldRetryOnError: false,
      isPaused: () => ids && ids.length > 1,
    }
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageItems, setPageItems] = useState(10);
  const [searchParams, setSearchParams] = useState<string>("");

  // Fetching the contact group data
  const {
    data: groups,
    error: err,
    isLoading: loading,
    mutate,
  } = useSWR<PaginatedContactGroups, any, any>(
    token
      ? [
          "api/contact-groups",
          token,
          { page: currentPage, limit: pageItems, search: searchParams },
        ]
      : null,
    swrFetcher,
    {
      //refreshInterval: 10000,

      revalidateOnFocus: true,
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );

  const { trigger: addContact, isMutating } = useSWRMutation(
    ids && ids?.length > 1
      ? "api/contact-groups/bulk-update"
      : `api/contacts/${contactID}`,
    swrMutationFetcher
  );
  const {
    data: TableContacts,
    isLoading: load,
    error: errs,
    mutate: mutateContact,
  } = useSWR<PaginatedContacts, any, any>(
    token
      ? [
          "api/contacts",
          token,
          { page: currentPage, limit: pageItems, search: searchParams },
        ]
      : null,
    swrFetcher,
    {
      //refreshInterval: 10000,

      revalidateOnFocus: true,
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );
  const [groupId, setGroupId] = useState<string[]>([]);
  const [allTags, setAllTags] = useState<CustomTag[]>([]);
  const [newTag] = useState<string[]>([]);
  function handleReset() {
    setAllTags([]);
    setGroupId([]);
  }
  // Initialize tags with the contact's existing groups
  useEffect(() => {
    if (data?.groups) {
      const initialTags = data.groups.map((group) => {
        const groupp = groups?.docs.find((g) => g.name === group.name);
        return {
          key: group?.id,
          label: `${groupp?.name} (${groupp?.contacts || 0})`,
        };
      });
      setAllTags(initialTags);
      setGroupId(initialTags.map((tag) => tag.key)); // Initialize groupId with existing group IDs
    }
  }, [data, groups?.docs]);

  const handleTagsChange = (updatedTags: CustomTag[]) => {
    setAllTags(updatedTags);
    // Update groupId based on tags
    setGroupId(updatedTags.map((tag) => tag.key));
  };

  const handleSearch = (search: string) => {
    setSearchParams(search);
  };

  const handleSelectionChange = (selectedItems: string[]) => {
    // Update tag state based on checkbox selection
    const updatedTags = selectedItems.map((label) => {
      const group = groups?.docs.find(
        (g) => `${g.name} (${g.contacts || 0})` === label
      );
      return {
        key: group?._id || "",
        label: `${label}`,
      };
    });
    handleGroups && handleGroups(updatedTags.map((group) =>  ({id:group.key, name:group.label})));

    setAllTags(updatedTags);
    // Update groupId based on selected checkbox items
    setGroupId(updatedTags.map((tag) => tag.key));
  };

  const addToGroup = async () => {
    const jsonBody = {
      contactGroupIds: groupId,
    };
    const multltipJson = {
      contactGroupIds: groupId,
      ...(ids && ids.length > 1 ? { contactIds: ids } : { contactIds: [""] }),
    };
    let requestOptions: RequestInit = {
      method: ids && ids.length > 1 ? "PUT" : "PATCH",
    };
    if (ids && ids.length > 1) {
      requestOptions.body = JSON.stringify(multltipJson);
      requestOptions.headers = {
        "Content-Type": "application/json", // Specify JSON content type
      };
    } else {
      requestOptions.body = JSON.stringify(jsonBody);
      requestOptions.headers = {
        "Content-Type": "application/json", // Specify JSON content type
      };
    }
    try {
      if (token) {
        const fetchArg: SwrMutationFetcherArgType = {
          token: token,
          requestOption: requestOptions,
        };
        const result: SuccessDataType<any> = await addContact(fetchArg);
        handleOpen && handleOpen();
        mutate();
        mutateContact();
        generateResult("success", capitalize(result.message));
        handleReset();
        handleDeselect && handleDeselect(true);
      }
    } catch (error: any) {
      const e = parseClientError(error);
      generateResult(
        "error",
        e.message || "Something went wrong. Try again later"
      );
      console.error(e);
    }
  };
  // if (error) {
  //   const e = parseClientError(error);
  //   message.error(capitalize(e.message) || "Something went wrong");
  // }

  function handleNewGroup() {
    setOpen((prev) => !prev);
    setSearchParams("");
  }
  const filteredGroups = React.useMemo(() => {
    return (
      groups?.docs.filter((group) =>
        group.name.toLowerCase().includes(searchParams.toLowerCase())
      ) || []
    );
  }, [groups, searchParams]);
  const handlePageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageItems(size);
  };
  if (error || err || errs) {
    const displayError = parseClientError(error || err || errs);
    return (
      <div className={s.spin}>
        <Unauthorised
          error={{
            title: "Error",
            message: capitalize(displayError.message) || "Something went wrong",
          }}
        />
      </div>
    );
  }
  return isLoading || loading ? (
    <div className={s.spin}>
      <Spin />
    </div>
  ) : (
    <>
      <ConfirmationModal
        handleCancel={() => handleOpen && handleOpen()}
        open={open}
        reset={handleReset}
        handleConfirmationModal={handleNewGroup}
      />
      <div className={s.wrapper}>
        {ids && ids?.length <= 1 && (
          <h3 className={s.name}>
            {data?.contact.firstName || data?.contact.lastName
              ? `${
                  data.contact.firstName
                    ? data?.contact.firstName.charAt(0).toUpperCase() +
                      data.contact.firstName.slice(1)
                    : ""
                } ${
                  data?.contact.lastName
                    ? data?.contact.lastName.charAt(0).toUpperCase() +
                      data.contact.lastName.slice(1)
                    : ""
                }`
              : `${data?.contact.email || data?.contact.phoneNo}`}
          </h3>
        )}
        <SearchInput
          handleSearch={handleSearch}
          placeholder="Search for group"
          height={45}
          width={420}
          className={s.searchInput}
        />
        <div className={s.paginate}>
          <Paginate
            onChange={handlePageChange}
            total={groups?.totalDocs || 0}
            pageSize={pageItems}
          />
        </div>
        <div className={s.tags}>
          {allTags.length > 0 ? (
            <Tags
              initialTags={allTags}
              newTag={newTag.length > 0 ? newTag[0] : undefined}
              onTagsChange={handleTagsChange}
            />
          ) : (
            ids && ids.length <= 1 && <p>No group added!</p>
          )}
        </div>
        <div className={s.checkbox}>
          {groups && groups.docs.length > 0 ? (
            <CheckboxList
              options={filteredGroups.map(
                (group) => `${group.name} (${group.contacts || 0})`
              )}
              defaultChecked={allTags.map((tag) => tag.label)}
              onSelectionChange={handleSelectionChange}
            />
          ) : (
            ids && ids.length <= 1 && <p>No group found</p>
          )}
        </div>
        {!messages && (
          <div className={s.addContactBtns}>
            <Button
              className={clsx(s.button, s.cancelBtn)}
              onClick={handleNewGroup}
            >
              Cancel
            </Button>
            <Button
              disabled={isMutating}
              loading={isMutating}
              className={clsx(s.button, s.addContactBtn)}
              onClick={addToGroup}
            >
              Done
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default AddToGroup;
