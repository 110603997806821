import clsx from "clsx";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import s from "./forgotPassword.module.scss";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { generateResult } from "../../../global components/ResultModal/ResultModal";
import {
  parseClientError,
  swrMutationFetcher,
} from "../../../libs/api/Endpoints";
import { SwrMutationFetcherArgType } from "../../../libs/api/Endpoints";
import Navigation from "../../../global components/Navigation/Navigation";
import { capitalize } from "../../../libs/common";
import { SuccessDataType } from "../../../libs/api/types";
import useDocumentMetadata from "../../../global components/PageTitle";
type Inputs = {
  email: string;
};
type forgotPasswordResponse = {
  _id: string | undefined;
};
function ForgotPassword() {
  const documentTitle = "Cloudivi | Forgot Password";
  const documentDescription =
    "Recover access to your Cloudivi account by resetting your password securely.";
  useDocumentMetadata(documentTitle, documentDescription);

  const navigate = useNavigate();
  const { trigger: enterEmail, isMutating } = useSWRMutation(
    "api/auth/forgot-password",
    swrMutationFetcher
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({ shouldFocusError: false });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("userId", data.email);
    try {
      const fetcherArg: SwrMutationFetcherArgType = {
        requestOption: {
          method: "post",
          body: urlEncodedData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      const UserID: SuccessDataType<forgotPasswordResponse> = await enterEmail(
        fetcherArg
      );
      generateResult(
        "success",
        "Enter the  One-Time Passcode (OTP) sent to you"
      );
      reset();

      navigate(`/auth/resetcode?userId=${UserID.data._id}&email=${data.email}`);
    } catch (error: any) {
      const e = parseClientError(error);
      generateResult(
        "error",
        capitalize(e.message) || "Something went wrong. Try again later"
      );
      console.error(e.error); // Log the error for debugging
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.navigate}>
        <Navigation />
      </div>
      <div className={s.main}>
        <span className={s.titles}>
          <h3 className={s.title}>Forgot password</h3>
          <p className={s.subtitle}>
            Provide the email address that is associated with this account
          </p>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={clsx(s.inputWrapper)}>
            <h5 className={clsx(s.inputLabel)}>Email address</h5>
            <input
              type="email"
              className={clsx(s.input, { [s.inputError]: errors.email })}
              {...register("email", { required: "Email is required" })}
            />
            {errors.email && (
              <span className={clsx(s.errorMessage)}>
                {errors.email.message}
              </span>
            )}
          </div>
          {/* Button component from Ant Design */}
          <Button
            type="default"
            className={clsx(s.button)} // Button style
            htmlType="submit" // Ensures it's tied to form submission
            loading={isMutating}
            disabled={isSubmitting}
          >
            Continue
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
