import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import {
  isTokenExpired,
  useAppSelector,
  useAppDispatch,
} from "./libs/Utils/customHooks";
import { logout} from "./libs/redux/auth/signinSlice";
import { generateResult } from "./global components/ResultModal/ResultModal";

function RequireAuth() {
  const location = useLocation();
  const dispatch = useAppDispatch(); // Get dispatch from custom hook
  const tok = useAppSelector((state) => state.signin.token);
  const token = localStorage.getItem("token") || tok;
  useEffect(() => {
    if (token) {
      if (isTokenExpired(token)) {
        // Token is expired
        dispatch(logout()); // Update Redux state to logout
        generateResult(
          "error",
          "Your session has expired. Please log in again."
        );
      }
    }
  }, [dispatch, token]); // Add token to dependencies for this effect

  return token && !isTokenExpired(token) ? (
    <Outlet />
  ) : (
    <Navigate to="/auth/signin" state={{ from: location }} replace />
  );
}

export default RequireAuth;
