import { SVGProps } from "react";

export default function MessageOne(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="1em"
        height="1em"
        {...props}
      >
        <defs>
          <mask id="ipSMessageOne0">
            <g fill="none" strokeLinecap="round" strokeWidth="4">
              <path
                fill="#fff"
                stroke="#fff"
                strokeLinejoin="round"
                d="M4 6h40v30H29l-5 5l-5-5H4z"
              ></path>
              <path stroke="#000" d="M23 21h2.003m7.998 0H35m-21.999 0H15"></path>
            </g>
          </mask>
        </defs>
        <path
          fill="currentColor"
          d="M0 0h48v48H0z"
          mask="url(#ipSMessageOne0)"
        ></path>
      </svg>
    )
  }
  