import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import s from "./Create.module.scss";
import { Button, Input, Switch } from "antd";
import clsx from "clsx";
import useDocumentMetadata from "../../../../global components/PageTitle";
import SuggestedKeyword from "./local_component/Sugestedkeywords";
import ResentKeywords from "./local_component/ResentKeywords";
import { UseAuthToken } from "../../../../libs/api/userSession";
import useSWRMutation from "swr/mutation";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../libs/api/Endpoints";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import ReusableModal from "../../../../global components/Modal/Modal";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../../libs/common";
import { PaginatedKeywords } from "../../../../libs/Utils/Types/SmartKeywords";
import useSWR from "swr";
const { TextArea } = Input;

type Inputs = {
  keyword: string;
  response: string;
};
function Create() {
  const query = useQuery();
  const id = query.get("keywordId");

  const token = UseAuthToken();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    data: keywords,
    isLoading,
    error,
    mutate,
  } = useSWR<PaginatedKeywords, any, any>(
    token ? ["api/smart-keywords", token] : null,
    swrFetcher,
    {
      revalidateOnFocus: true,
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );

  const { trigger: createKeywords, isMutating } = useSWRMutation(
    `api/smart-keywords/${id ? id : ""}`,
    swrMutationFetcher
  );
  const [clear, setClear] = useState<string | null>(null);
  const documentTitle = "Cloudivi | Create SmartKeywords";
  const documentDescription =
    "Create smart keywords send automated messages to your audience with Cloudivi.";
  useDocumentMetadata(documentTitle, documentDescription);
  const [swicth, setSwitch] = useState<"active" | "inactive">("active");
  const {
    reset,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();
  const details = useMemo(
    () => keywords?.docs.find((item) => item.id === id),
    [id, keywords?.docs]
  );

  const onChange = (checked: boolean) => {
    if (checked) {
      setSwitch("active");
    } else {
      setSwitch("inactive");
    }
  };
  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };
  const onSubmit = async () => {
    try {
      handleModal();
      const body = JSON.stringify({
        keyword: getValues("keyword").trimEnd(),
        response: getValues("response"),
        status: swicth,
      });
      const result: SwrMutationFetcherArgType = {
        token,
        requestOption: {
          body,
          method: id ? "PATCH" : "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
      };
      await createKeywords(result);
      mutate();
      handleReset()
      id && navigate(-1);
    } catch (error) {
      setOpenModal(false);
      const e = parseClientError(error);
      generateResult("error", e.message);
    }
  };
  const responseLength = watch("response", "").length;
  const keywordLength = watch("keyword", "").length;

  const handleKeyword = useCallback(
    (word: string | null) => {
      setValue("keyword", word ?? ""); // If word is undefined, set empty string
      setClear(word);
    },
    [setValue]
  );

  const handleReset = useCallback(() => {
    setClear(null);
    if (id && details) {
      setValue("keyword", details.keyword);
      setValue("response", details.response);
      setSwitch(details.status === "Active" ? "active" : "inactive");
    } else {
      reset();
    }
  }, [details, id, reset, setValue]);
  const handleRetry = () => {
    setClear(null);

    handleModal();

    reset();
  };

  useEffect(() => {
    if (!error && details && !isLoading && id) {
      setValue("keyword", details.keyword);
      setValue("response", details.response);
      setSwitch(details.status === "active" ? "active" : "inactive");
    }
  }, [details, error, id, isLoading, setValue]);
  console.log(details);
  return (
    <>
      <ReusableModal
        title={""}
        isModalOpen={openModal}
        handleModal={handleModal}
        maskClosable={true}
      >
        {isMutating ? (
          <div className={s.modalWrapper}>
            <Oval
              visible={true}
              height="35"
              width="35"
              color="#dc4e44"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
            <p className={s.loadingText}>
              {id ? "Updating smart keyword..." : "Creating smart keyword"}
            </p>
          </div>
        ) : id ? (
          <div className={s.modalWrapper}>
            <h3 className={s.title}>Smart keyword successfully updated.</h3>
            <p className={s.description}>
              You have successfully updated a smart keyword.
            </p>
          </div>
        ) : (
          <div className={s.modalWrapper}>
            <h3 className={s.title}>Smart keyword successfully created.</h3>
            <p className={s.description}>
              You have successfully created a new smart keyword. It's now active
              and tracking interactions.
            </p>
            <div className={s.buttons}>
              <button onClick={handleRetry} className={s.cancelBtn}>
                Create another{" "}
              </button>
              <Button
                onClick={() => navigate(-1)}
                className={clsx(s.submitBtn)}
              >
                Return to dashboard
              </Button>
            </div>
          </div>
        )}
      </ReusableModal>
      <div className={s.wrapper}>
        <div className={s.header}>
          <div className={s.msgTitleWrapper}>
            <h1 className={s.msgTitle}>{watch("keyword") || "Untitled"}</h1>
          </div>
          <div className={s.buttons}>
            <button onClick={handleReset} className={s.cancelBtn}>
              Cancel
            </button>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={!responseLength || !keywordLength}
              className={clsx(s.submitBtn, {
                [s.disabledBtn]: !responseLength || !keywordLength,
              })}
            >
              {id ? "Save changes" : "Save keyword"}
            </Button>
          </div>
        </div>
        <div className={s.main}>
          <section className={s.sectionOne}>
            <div className={s.inputWrapper}>
              <h5 className={s.label}>Smart keyword</h5>
              <Controller
                name="keyword"
                control={control}
                rules={{
                  required: "Keyword is required",
                  validate: (value) =>
                    !["unsubscribe", "stop"].includes(value.toLowerCase()) ||
                    "Keyword cannot be 'stop' or 'unsubscribe'",
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    disabled={id ? true : false}
                    value={field.value}
                    count={{
                      show: true,
                      max: 15,
                    }}
                    className={clsx(s.input, {
                      [s.inputError]: errors.keyword,
                    })}
                    maxLength={15}
                  />
                )}
              />
              {!errors.keyword && (
                <span className={s.helperText}>
                  <p className={s.titleMsg}>
                    The preferred keyword cannot be stop or unsubscribe
                  </p>
                </span>
              )}
              {errors.keyword && (
                <span className={clsx(s.errorMessage)}>
                  {errors.keyword.message}
                </span>
              )}
            </div>
            <div className={s.inputWrapper}>
              <h5 className={s.label}>Automated response</h5>
              <Controller
                name="response"
                control={control}
                rules={{
                  required: "Automated response is required",
                }}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    showCount
                    maxLength={130}
                    className={clsx(s.textArea, {
                      [s.inputError]: errors.response,
                    })}
                  />
                )}
              />

              {errors.response && (
                <span className={clsx(s.errorMessage)}>
                  {errors.response.message}
                </span>
              )}
            </div>
            {
              <div className={s.switchWrapper}>
                <p className={s.label}>Enable/Disable smart keyword</p>
                <Switch
                  defaultChecked={
                    !id ? true : details?.status === "inactive" ? false : true
                  }
                  onChange={onChange}
                  className={s.switch}
                  style={{
                    backgroundColor:
                      swicth === "active" ? "#D52B1E" : "#BDBDBD",
                  }}
                />
              </div>
            }
            <div className={s.mobileBtn}>
              <button onClick={handleReset} className={s.cancelBtn}>
                Cancel
              </button>
              <Button
                onClick={handleSubmit(onSubmit)}
                disabled={!responseLength || !keywordLength}
                className={clsx(s.submitBtn, {
                  [s.disabledBtn]: !responseLength || !keywordLength,
                })}
              >
                {id ? "Save changes" : "Save keyword"}
              </Button>
            </div>
          </section>
          <section className={s.sectionTwo}>
            {!id && (
              <SuggestedKeyword handleKeyword={handleKeyword} clear={clear} />
            )}
            <ResentKeywords
              keywords={keywords}
              isLoading={isLoading}
              error={error}
            />
          </section>
        </div>
      </div>
    </>
  );
}

export default Create;
