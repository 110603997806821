import React, { useCallback, useEffect,  useState } from "react";
import { Input } from "antd";
import type { GetProps } from "antd";
import Search from "../../Icons/Search";
import "./SearchInput.scss";
import { useDebounce } from "use-debounce";

type SearchProps = GetProps<typeof Input> & {
  handleSearch: (value: string) => void; // Specify the type for the search value
  width?: number;
  height?: number;
  placeholder?: string;
};

function SearchInput({ handleSearch, placeholder }: SearchProps) {
  const [value, setValue] = useState(""); // State for the input value
  const [debouncedValue] = useDebounce(value, 1000);
  const memoizedHandleSearch = useCallback(handleSearch, [handleSearch]);

  useEffect(() => {
    // Only trigger handleSearch if the debouncedValue is non-empty
    if (debouncedValue.trim() !== "") {
      memoizedHandleSearch(debouncedValue);
    }
  }, [debouncedValue, memoizedHandleSearch]);
  return (
    <Input
      placeholder={placeholder}
      prefix={<Search height={15} width={15} fill="#80828A" />}
      className={"input"}
      style={{
        width: `100%`,
        height: `100px`,
        borderRadius: "8px",
        display: "flex",
        alignItems: "center", // Ensures text and icon are centered
      }}
      value={value}
      onChange={(e) => setValue(e.target.value)} // Update state on input change
    />
  );
}

export default SearchInput;
