import React from "react";
import { logout } from "../../../../libs/redux/auth/signinSlice";
import { useAppDispatch } from "../../../../libs/Utils/customHooks";
import s from "./Logout.module.scss";
import { generateConfirmation } from "../../../../global components/DestructiveModal/DestruvtiveModal";
import { useNavigate } from "react-router-dom";
function Logout() {
  const dispatch = useAppDispatch();
  const navigate=useNavigate()
  async function handleLogout() {
    dispatch(logout());
    navigate("/auth")
  }
  return (
    <div>
      <button
        className={s.logoutBtn}
        onClick={() =>
          generateConfirmation(
            "Log out?",
            "Are you sure you want to log out?",
            handleLogout,
            true,
            "log out"
          )
        }
      >
        Logout
      </button>
    </div>
  );
}

export default Logout;
