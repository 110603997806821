import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchBaseLocation } from "../../common";

// Define Avatar interface
export interface Avatar {
  publicId: string | undefined;
  url: string | undefined;
}

// Define User interface
export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  avatar: Avatar;
  credits: number;
  isVerified: boolean;
  noOfContacts: number;
  noOfMessages: number;
  noOfContactGroups: number;
  verificationStatus: "verified" | "pending" | "unverified";
  lastLoginAt: string;
  applicationNumbers:string[]
}

// Response type for sign-in
export interface SignInResponse {
  success: boolean;
  data: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    avatar: Avatar;
    credits: number;
    isVerified: boolean;
    token: string; // Not typically part of User; consider moving to state instead
    noOfContacts: number;
    noOfMessages: number;
    noOfContactGroups: number;
  };
  message: string;
}

// Define state shape
interface AuthState {
  message: string;
  success: boolean;
  loading: boolean;
  error: string | null;
  user: User | null;
  token: string | null;
}

// Initial state
const initialState: AuthState = {
  message: "",
  success: false,
  loading: false,
  error: null,
  user: null,
  token: localStorage.getItem("token") || null, // Set token from localStorage
};

// Async thunk for signing in
export const signIn = createAsyncThunk(
  "auth/signIn",
  async (signInData: URLSearchParams, thunkAPI) => {
    try {
      const response = await axios.post(
        `${fetchBaseLocation}api/auth/sign-in`,
        signInData
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Something went wrong. Try again later",
        }
      );
    }
  }
);

// Create auth slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserFromToken(state, action: PayloadAction<string>) {
      const token = action.payload;
      state.token = token;
    },
    logout(state) {
      state.user = null;
      state.token = null;
      state.success = false;
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error on new request
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false; // Stop loading
        state.success = action.payload.success; // Set success status
        state.message = action.payload.message; // Store message
        state.user = {
          applicationNumbers:action.payload.applicationNumbers,
          avatar: action.payload.data.avatar,
          credits: action.payload.data.credits,
          email: action.payload.data.email,
          firstName: action.payload.data.firstName,
          lastName: action.payload.data.firstName,
          _id: action.payload.data.id,
          isVerified: action.payload.data.isVerified,
          noOfContactGroups: action.payload.data.noOfContactGroups,
          noOfContacts: action.payload.data.noOfContacts,
          noOfMessages: action.payload.data.noOfMessages,
          phoneNo: action.payload.data.phoneNo,
          verificationStatus: action.payload.verificationStatus,
          lastLoginAt: action.payload.lastLoginAt,
        }; // Store user info
        state.token = action.payload.data.token; // Store token
        localStorage.setItem("token", action.payload.data.token); // Save token to localStorage
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false; // Stop loading
        state.error = action.error.message || "Sign-in failed"; // Set error message
      });
  },
});

// Export actions and reducer
export const { setUserFromToken, logout } = authSlice.actions;
export default authSlice.reducer;
