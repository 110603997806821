import React, { useState } from "react";
import s from "./Unsubscribe.module.scss";
import cloudiviLogo from "../../assets/images/Cloudivi white.webp";
import { Button } from "antd";
import {
  parseClientError,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../libs/api/Endpoints";
import useSWRMutation from "swr/mutation";
import { capitalize, useQuery } from "../../libs/common";
import { generateResult } from "../../global components/ResultModal/ResultModal";
import { useNavigate } from "react-router-dom";
type NewsUpdates = {
  tooMuch: boolean;
  notRelevant: boolean;
  notHelpful: boolean;
  notInterested: boolean;
};
function Unsubscribe() {
  const query = useQuery();
  const email = query.get("email");
  const navigate = useNavigate();
  const [newsUpdates, setNewsUpdates] = useState({
    tooMuch: false,
    notRelevant: false,
    notHelpful: false,
    notInterested: false,
  });
  const { trigger: subscribe, isMutating } = useSWRMutation(
    "api/newsletter/subscribe",
    swrMutationFetcher
  );
  const { trigger: unSubscribe, isMutating: isLoading } = useSWRMutation(
    "api/newsletter/subscribe",
    swrMutationFetcher
  );
  const convertToURLEncoded = (obj: NewsUpdates): string => {
    return Object.entries(obj)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
      )
      .join("&");
  };
  const handleNewsUpdatesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setNewsUpdates((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const urlEncodedString = convertToURLEncoded(newsUpdates);
    try {
      const fetcherArg: SwrMutationFetcherArgType = {
        requestOption: {
          method: "POST",
          body: urlEncodedString,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      await unSubscribe(fetcherArg);
      generateResult(
        "success",
        "You have un-subscribed to our newsletter successfully"
      );
      navigate("/");
    } catch (error) {
      const e = parseClientError(error);
      generateResult(
        "error",
        capitalize(e.message) || "An unexpected error occurred."
      );
    }
  }

  const onSubmit = async () => {
    const urlEncodedData = new URLSearchParams();
    if (email) urlEncodedData.append("email", email);
    try {
      const fetcherArg: SwrMutationFetcherArgType = {
        requestOption: {
          method: "POST",
          body: urlEncodedData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      await subscribe(fetcherArg);
      generateResult(
        "success",
        "You have subscribed to our newsletter successfully"
      );
      navigate("/");
    } catch (error) {
      const e = parseClientError(error);
      generateResult("error", e.message || "An unexpected error occurred.");
    }
  };
  return (
    <div className={s.wrapper}>
      <header className={s.header}>
        <img
          onClick={() => navigate("/")}
          src={cloudiviLogo}
          className={s.logo}
          alt="cloudivo logo"
        />
      </header>
      <section className={s.body}>
        <div className={s.main}>
          <h2 className={s.successMsg}>Unsubscribe successful</h2>
          <h5 className={s.bodyMsg}>
            You will no longer receive emails from us.
          </h5>
        </div>
        <form className={s.inputs} onSubmit={handleSubmit}>
          <h4 className={s.subtitle}>
            If you have a moment, please let us know why you unsubscribed:
          </h4>
          <div className={s.input}>
            <input
              type="checkbox"
              id="tooMuch"
              name="feedback_reason"
              //value="tooMuch"
              checked={newsUpdates.tooMuch}
              onChange={handleNewsUpdatesChange}
            />
            <label htmlFor="tooMuch">
              Too many emails – I was getting too many notifications.
            </label>
          </div>
          <div className={s.input}>
            <input
              type="checkbox"
              id="notRelevant"
              name="feedback_reason"
              //value="Car"
              checked={newsUpdates.notRelevant}
              onChange={handleNewsUpdatesChange}
            />
            <label htmlFor="notRelevant">
              Content not relevant – The emails don’t match what I’m interested
              in.
            </label>
          </div>
          <div className={s.input}>
            <input
              type="checkbox"
              id="notHelpful"
              name="feedback_reason"
              value="Boat"
              checked={newsUpdates.notHelpful}
              onChange={handleNewsUpdatesChange}
            />
            <label htmlFor="notHelpful">
              Emails are not helpful – I didn’t find the content valuable.
            </label>
          </div>
          <div className={s.input}>
            <input
              type="checkbox"
              id="notInterested"
              name="feedback_reason"
              //value="Boat"
              checked={newsUpdates.notInterested}
              onChange={handleNewsUpdatesChange}
            />
            <label htmlFor="notInterested">
              Just not interested anymore – I’m no longer interested in
              receiving updates.
            </label>
          </div>

          <Button className={s.submitBtn} loading={isLoading} htmlType="submit">
            Send feeback
          </Button>
        </form>
      </section>
      <span className={s.footer}>
        <p className={s.reSubscribe}> Unsubscribed by mistake?</p>
        <Button className={s.button} loading={isMutating} onClick={onSubmit}>
          Re-subscribe
        </Button>
      </span>
    </div>
  );
}

export default Unsubscribe;
