import React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import s from "./Signin.module.scss";
import clsx from "clsx";
import { Button, Input } from "antd";
import { useAppDispatch } from "../../../libs/Utils/customHooks";
import { signup } from "../../../libs/redux/auth/signupSlice";
import EyeClose from "../../../global components/Icons/EyeClose/EyeClose";
import EyeOpen from "../../../global components/Icons/EyeOpen/EyeOpen";
import { SuccessDataType } from "../../../libs/api/types";
import useDocumentMetadata from "../../../global components/PageTitle";
type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};
type signupResponse = {
  id: string;
  email: string;
};
function SignIn() {
  const documentTitle = "Cloudivi | Signup";
  const documentDescription =
    "Create a new Cloudivi account and start sending messages instantly.";
  useDocumentMetadata(documentTitle, documentDescription);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Inputs>({ shouldFocusError: false });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("firstName", data.firstName);
    urlEncodedData.append("lastName", data.lastName);
    urlEncodedData.append("email", data.email);
    urlEncodedData.append("password", data.password);
    try {
      const result: SuccessDataType<signupResponse> = await dispatch(
        signup(urlEncodedData)
      ).unwrap();
      navigate(
        `/auth/verify-email/?userId=${result.data.id}&email=${result.data.email}`,
        {
          state: {
            email: data.email,
            password: data.password,
          },
        }
      );
      reset();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className={s.wrapper}>
      <span className={s.titles}>
        <h3 className={s.title}>Create account</h3>
        <p className={s.subtitle}>Sign up and get free credits for testing </p>
      </span>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={clsx(s.fullname)}>
          <div className={clsx(s.inputWrapper)}>
            <h5 className={clsx(s.inputLabel)}>First name</h5>
            <input
              className={clsx({ [s.inputfirstnameError]: errors.firstName })}
              {...register("firstName", { required: "First name is required" })}
            />
            {errors.firstName && (
              <span className={clsx(s.errorMessage)}>
                {errors.firstName?.message}
              </span>
            )}
          </div>
          <div className={clsx(s.inputWrapper)}>
            <h5 className={clsx(s.inputLabel)}>Last name</h5>
            <input
              className={clsx({ [s.inputlastnameError]: errors.lastName })}
              {...register("lastName", { required: "Last name is required" })}
            />
            {errors.lastName && (
              <span className={clsx(s.errorMessage)}>
                {errors.lastName?.message}
              </span>
            )}
          </div>
        </div>

        <div className={clsx(s.inputWrapper)}>
          <h5 className={clsx(s.inputLabel)}>Email address</h5>
          <input
            className={clsx(s.input, { [s.inputError]: errors.email })}
            {...register("email", { required: "Email is required" })}
          />
          {errors.email && (
            <span className={clsx(s.errorMessage)}>{errors.email.message}</span>
          )}
        </div>
        <div className={clsx(s.inputWrapper)}>
          <h5 className={clsx(s.inputLabel)}>Password</h5>
          <Controller
            name="password"
            control={control}
            rules={{
              required: "Password is required",
              minLength: {
                value: 6,
                message: "Password must be at least 6 characters long",
              },
              pattern: {
                value:
                  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{6,}$/,
                message:
                  "Password must include at least one uppercase letter, one number, and one special character",
              },
            }}
            render={({ field }) => (
              <Input.Password
                {...field} // Spread the field props here
                placeholder="input password"
                className={clsx(s.input, s.customPasswordInput, {
                  [s.inputPasswordError]: errors.password,
                })}
                iconRender={(visible) =>
                  visible ? (
                    <EyeOpen fill="#6A6767" />
                  ) : (
                    <EyeClose fill="#6A6767" />
                  )
                }
              />
            )}
          />
        </div>
        {errors.password && (
          <span className={clsx(s.errorMessage)}>
            {errors.password.message}
          </span>
        )}
        <Button
          type="default"
          className={clsx(s.button)} // Button style
          htmlType="submit" // Ensures it's tied to form submission
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Create account
        </Button>
        {/* <span className={s.alternative}>
          <p className={s.or}>Or</p>
        </span>
        <span className={s.siginWithGoogle}>
          <img src={google} className={s.google} alt="google logo" />
          <h5>Continue with Google</h5>
        </span>
        <span className={s.siginWithApple}>
          <AppleFilled width={24} height={24} />
          <h5>Continue with Apple</h5>
        </span> */}
        <span className={s.createAccount}>
          <p>Have an account?</p>
          <Link to="/auth/signin" className={s.links}>
            Sign in
          </Link>
        </span>
      </form>
    </div>
  );
}

export default SignIn;
