import { ColumnType } from "antd/es/table";
import clsx from "clsx";
import React, { useMemo, useState } from "react";
import s from "./Table.module.scss";
import ReusablePopover from "../../../../global components/Popover/ReuseablePopover";
import dayjs from "dayjs";
import { PaginatedKeywordsDetails } from "../../../../libs/Utils/Types/SmartKeywords";
import { UseAuthToken } from "../../../../libs/api/userSession";
import useSWR from "swr";
import { parseClientError, swrFetcher } from "../../../../libs/api/Endpoints";
import Elipses from "../../../../global components/Icons/Elipses";
import Paginate from "../../../../global components/Paginate/Pagination";
import ReusableTable from "../../../../global components/Table/Table";
import Unauthorised from "../../../../global components/Unauthorised";
import { capitalize } from "../../../../libs/common";
import NoData from "../../../../global components/Table/NoData/NoData";
import { useMediaQuery } from "react-responsive";
import SearchInput from "../../../../global components/Forms/SearchInput/SearchInput";
import SelectField from "../../../../global components/Forms/SelectField/SelectField";
import Keywords from "../../../../global components/Icons/Keywords";
import EyeOpen from "../../../../global components/Icons/EyeOpen/EyeOpen";
import ReusableDrawer from "../../../../global components/Drawer/ReuseableDrawer";
import Details from "./local_component/Details";
import ReusableModal from "../../../../global components/Modal/Modal";
import { useParams } from "react-router-dom";

interface DataType {
  key: string;
  phoneNo: string;
  createdAt: string;
  response: string;
  creditUsed: string;
  actions: string;
}
function ViewKeyWords() {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [iD, setID] = useState<string>("");
  const token = UseAuthToken();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageItems, setPageItems] = useState(10);
  const [filter, setFilter] = useState<string>("newest");
  const { id } = useParams(); // Get email from URL params

  const {
    data: keywords,
    isLoading,
    error,
  } = useSWR<PaginatedKeywordsDetails, any, any>(
    token
      ? [
          `api/smart-keywords/${id}`,
          token,
          {
            page: currentPage,
            limit: pageItems,
            search: searchValue,
            sortBy: filter,
          },
        ]
      : null,
    swrFetcher,
    {
      revalidateOnFocus: true,
      refreshInterval: 5000,
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );
  const handleSearch = (value: string) => {
    setSearchValue(value);
    // You can add your search logic here, e.g., fetching data based on the search term
  };
  const handleDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };

  const handleId = (key: string) => {
    setID(key);

    handleDrawer();
  };
  const handlePageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageItems(size);
  };
  function handleFilter(value: string) {
    setFilter(value);
  }
  const getColumns = (): ColumnType<DataType>[] => {
    const columns: ColumnType<DataType>[] = [
      {
        title: "S/N",
        dataIndex: "serialNumber",
        key: "serialNumber",
        render: (serialNumber: number) => (
          <span className={clsx(s.cell, s.messageTitle)}>{serialNumber}</span>
        ),
      },
      {
        title: "Phone number",
        dataIndex: "phoneNo",
        key: "phoneNo",
        render: (phoneNo: string, record: { key: string }) => (
          <span className={clsx(s.cell, s.messageTitle)}>{phoneNo}</span>
        ),
      },

      {
        title: "Response",
        dataIndex: "response",
        key: "response",
        render: (response: string) => (
          <span className={clsx(s.cell)}> {response}</span>
        ),
      },
      {
        title: "Creation date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: string, record: { key: string }) => {
          return (
            <span className={clsx(s.cell)}>
              {dayjs(createdAt).format("MMM D, YYYY")}
            </span>
          );
        },
      },
      {
        title: "Credit used",
        dataIndex: "creditUsed",
        key: "creditUsed",
        render: (creditUsed: string) => (
          <span className={clsx(s.cell)}>{creditUsed}</span>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text: any, record: { key: string }) => {
          return (
            <ReusablePopover
              trigger={"click"}
              placement="rightTop"
              content={
                <span
                  onClick={() => handleId(record.key)}
                  className={s.PopOverItem}
                >
                  <EyeOpen fill="#240806" />
                  <h5>View details</h5>
                </span>
              } // Pass the key value
            >
              <div className="actions-column">
                <Elipses height={4} width={18} />
              </div>
            </ReusablePopover>
          );
        },
      },
    ];
    return columns;
  };
  const transformedData = keywords?.hits.map((contact, index) => ({
    serialNumber: index + 1,

    key: contact._id,
    phoneNo: contact.hits.phoneNo,
    response: contact.hits.response,
    createdAt: contact.hits.createdAt,
    creditUsed: contact.hits.creditsUsed,

    actions: "", // You can customize actions here as needed
  }));
  const details = useMemo(() => keywords?.hits.find((item) => item._id === iD),[iD, keywords?.hits]);
  return (
    <>
      <ReusableDrawer
        title={details?.hits.phoneNo || "N/A"}
        Open={openDrawer}
        handleOpen={handleDrawer}
      >
        <Details
          creditUsed={details?.hits.creditsUsed || "N/A"}
          date={dayjs(details?.hits.createdAt).format("MMM D, YYYY") || "N/A"}
          loading={false}
          response={details?.hits.response || "N/A"}
          handleReset={handleDrawer}
        />
      </ReusableDrawer>
      <ReusableModal
        title={details?.hits.phoneNo || "N/A"}
        isModalOpen={isMobile && openDrawer}
        handleModal={handleDrawer}
      >
        <Details
          creditUsed={details?.hits.creditsUsed || "N/A"}
          date={dayjs(details?.hits.createdAt).format("MMM D, YYYY") || "N/A"}
          loading={false}
          response={details?.hits.response || "N/A"}
          handleReset={handleDrawer}
        />
      </ReusableModal>
      <div className={s.table}>
        <span className={s.searchParams}>
          <div className={s.filters}>
            <SearchInput
              placeholder="search"
              handleSearch={handleSearch}
              width={332}
              height={45}
              className={s.input}
              rootClassName={s.input}
            />
            <SelectField
              label={"Status:"}
              wrapperClassname={s.SelectWrapper}
              bordered
              onChange={(value: any) => handleFilter(value)}
              defaultValue={"newest"}
              className={s.selectInput}
              style={{ width: "100%", height: "100%" }}
              options={[
                { value: "newest", label: "Newest" },
                { value: "oldest", label: "Oldest" },
              ]}
            />
          </div>
          <div className={s.paginate}>
            <Paginate
              onChange={handlePageChange}
              total={keywords?.total || 0}
              pageSize={pageItems}
            />
          </div>
        </span>
        <div className={s.rTable}>
          <ReusableTable
            loading={isLoading}
            noDataComponent={
              error ? (
                <Unauthorised
                  error={{
                    title: "Error",
                    message:
                      capitalize(parseClientError(error).message) ||
                      "Something went wrong",
                  }}
                />
              ) : (
                <NoData
                  Message="No smart keywords found"
                  subMessage="Get started by creating a smart keyword"
                  icon={
                    <Keywords
                      fill="#F0EFEE"
                      height={isMobile ? 32 : 60}
                      width={isMobile ? 32 : 80}
                    />
                  }
                />
              )
            }
            dataSource={transformedData || []}
            columns={getColumns()}
          />
        </div>
      </div>
    </>
  );
}

export default ViewKeyWords;
