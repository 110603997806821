import React, { useCallback, useEffect, useRef, useState } from "react";
import RichTextEditor from "../../../../global components/React Quill Text Editor/QuilEditor";
import s from "./Email.module.scss";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { Input, message } from "antd";
import {
  ContactGroup,
  PaginatedContactGroups,
} from "../../../../libs/Utils/Types/ContactGroup";
import { UseAuthToken } from "../../../../libs/api/userSession";
import useSWR from "swr";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../libs/api/Endpoints";
import { useNavigate } from "react-router-dom";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import ReusableDrawer from "../../../../global components/Drawer/ReuseableDrawer";

import dayjs from "dayjs";
import { capitalize, useQuery } from "../../../../libs/common";
import useSWRMutation from "swr/mutation";
import ReusableModal from "../../../../global components/Modal/Modal";

import { Oval, ThreeDots } from "react-loader-spinner";
import utc from "dayjs/plugin/utc";
import { SuccessDataType } from "../../../../libs/api/types";
import SetSchedule from "../SetSchedule";
import { useMediaQuery } from "react-responsive";
import { useToggle } from "../../../../global components/Context/NavbarContext";
import Recipients from "../Recipients";
import { MessageDetailsType } from "../../../../libs/Utils/Types/Message";
import { User } from "../../../../libs/redux/auth/signinSlice";

dayjs.extend(utc);
type Inputs = {
  msgTitle: string;
  emailSubject: string;
  recipient: string;
  date: string;
  time: string;
  from: string;
  replyTo: string;
};

function Email() {
  

  const query = useQuery();
  const groupId = query.get("groupId");
  const msgId = query.get("msgId");
  const [contactIds, setContactIds] = useState<string[]>([]);
  const [contactGroupIds, setContactGroupIds] = useState<string[]>([]);
  const [contactGroupNames, setContactGroupNames] = useState<string[]>([]);
  const handleContacts = (contactsIds: string[]) => {
    setContactIds(contactsIds);
  };

  const handleGroups = (groupsIds: Array<{ name: string; id: string }>) => {
    setContactGroupIds(groupsIds.map((item) => item.id));
    setContactGroupNames(groupsIds.map((item) => item.name));
  };
  const [openGroup, setOpenGroup] = useState<boolean>(false);
  const [, /*checkboxGroups*/ setCheckboxGroups] = useState<ContactGroup[]>();
  const [, /*recipient*/ setRecipients] = useState<string>("");
  const token = UseAuthToken();
  const [msgID, setmsgId] = useState<string>("");
  const cachedData: User = JSON.parse(
    localStorage.getItem("user_data") || "{}"
  );

  const { trigger: submitMsg } = useSWRMutation(
    `api/messages/${msgID ? msgID : ""}?`,
    swrMutationFetcher
  );
  const { trigger: uploadImage } = useSWRMutation(
    "api/media/upload",
    swrMutationFetcher
  );
  const { data: groups, isLoading: loadGroups } = useSWR<
    PaginatedContactGroups,
    any,
    any
  >(token ? [`api/contact-groups`, token] : null, swrFetcher, {
    shouldRetryOnError: true,
  });

  const {
    data: MessageDetails,
    isLoading,
    error,
  } = useSWR<MessageDetailsType, any, any>(
    token && msgId ? [`api/messages/${msgId}`, token] : null,
    swrFetcher,
    {
      shouldRetryOnError: true,
    }
  );
  const [content, setContent] = useState(MessageDetails?.body || "");

  const {
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    reset,
  } = useForm<Inputs>({ shouldFocusError: false });
  const [, /*groupIds*/ setGroupsId] = useState<string[]>([]);
  const [, /*recipients*/ setRecipient] = useState<string>("");
  const navigate = useNavigate();
  const [images, setImages] = useState<Blob[]>([]);
  const [date, setDate] = useState<string | null>(null);
  function handleCombined(dates: string | null) {
    if (dates) {
      setDate(dates);
    }
  }
  // Handler to receive edited content
  const handleEdit = (newContent: string) => {
    setContent(newContent);
  };
  const handleImageUpload = async (blob: Blob) => {
    // You can handle the blob here, for example, upload it to a server

    // Optionally, convert the blob to an object URL if you want to display it

    setImages((prevImages) => [...prevImages, blob]);
  };
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const hasUpdated = useRef(false);
  const pendingRef = useRef(false); // Prevents duplicate execution

  const handleDraft = useCallback(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      if (pendingRef.current) return; // 🚀 Prevent duplicate execution

      try {
        pendingRef.current = true; // 🚀 Lock execution

        const formData = new FormData();

        // Append fields conditionally
        if (watch("replyTo")) formData.append("replyTo", watch("replyTo"));
        if (watch("msgTitle")) formData.append("title", watch("msgTitle"));
        if (watch("from")) formData.append("senderName", watch("from"));
        if (watch("emailSubject"))
          formData.append("subject", watch("emailSubject"));
        formData.append("type", "email");

        if (contactGroupIds.length) {
          contactGroupIds.forEach((id) =>
            formData.append("contactGroupIds[]", id)
          );
        } else if (groupId) {
          formData.append("contactGroupIds[]", groupId);
        }
        if (contactIds.length) {
          contactIds.forEach((id) => formData.append("contactIds[]", id));
        }

        let updatedContent = content;

        // Process images if any
        if (images.length > 0) {
          for (const image of images) {
            const img = await handleEmail(image);
            updatedContent = updatedContent.replace(
              /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/g,
              `<img src="${img}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
            );
          }
          updatedContent = updatedContent.replace(/https:\/\//g, "http://");
        }

        formData.append("body", updatedContent);

        // Helper function to handle draft submission
        const submitDraft = async () => {
          if (
            watch("msgTitle") &&
            watch("emailSubject") &&
            (groupId || contactGroupIds.length || contactIds.length)
          ) {
            formData.append("isDraft", "true");

            const fetcherArg: SwrMutationFetcherArgType = {
              token,
              requestOption: {
                method: msgID ? "PATCH" : "POST",
                body: formData,
              },
            };

            const result: SuccessDataType<{ id: string }> = await submitMsg(
              fetcherArg
            );
            hasUpdated.current = true;

            if (!msgId && (!msgID || !hasUpdated.current)) {
              setmsgId(result.data.id);
            }
          }
        };

        await submitDraft();
      } catch (error) {
        console.error("Failed to submit draft:", error);
      } finally {
        pendingRef.current = false; // 🚀 Unlock execution (prevents double runs)
      }
    }, 500); // 0.5-second debounce

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [
    watch,
    contactGroupIds,
    groupId,
    contactIds,
    content,
    images,
    handleEmail,
    token,
    msgID,
    submitMsg,
    msgId,
  ]);

  const watchedFields = watch(["msgTitle", "emailSubject", "recipient"]);
  const watchedRecipient = watch(["recipient"]);

  // Effect to call handleDraft whenever content changes
  useEffect(() => {
    handleDraft();
  }, [handleDraft, watchedFields, content]);
  // console.log(contactGroupIds);
  // console.log(contactIds);
  useEffect(() => {
    if (watch("recipient")) {
      setRecipients(watch("recipient"));
    }
  }, [watch, watchedRecipient]);
  // Submit handler for the form
  const onSubmit = async (data: Inputs) => {
    if (!content) {
      generateResult("error", "You must have a body!");
      return;
    }
    if (!groupId && contactGroupIds.length === 0 && contactIds.length === 0) {
      generateResult(
        "error",
        "No recipients!",
        "Select a group with at least one contact or select the contacts you want to send message to"
      );
      return;
    }

    try {
      if (date) {
        const combinedDateTime = date;
        // Navigate to the review page with the form data and formatted date/time
        let newString: string = "";
        if (images.length > 0) {
          let newString = content;

          // Process each image in the images array
          for (const image of images) {
            const img = await handleEmail(image);
            // Replace each <img> tag with the processed image URL
            newString = newString.replace(
              /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
              `<img src="${img}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
            );
          }

          // Update any remaining content as necessary
          newString = newString.replace(/https:\/\//g, "http://");
          navigate("review", {
            state: {
              ...data,
              type: "email",
              content: newString,
              contactIds: contactIds,
              contactGroupIds: contactGroupIds,
              groupId: groupId,

              contactGroupNames: contactGroupNames,
              //recipient: groupIds,
              schedule: combinedDateTime
                ? combinedDateTime
                : MessageDetails?.scheduledFor && MessageDetails?.scheduledFor,

              msgId: msgID || msgId,
              reset: reset(),
            },
          });
        } else {
          newString = content;
          navigate("review", {
            state: {
              ...data,
              type: "email",
              content: newString,
              contactIds: contactIds,
              contactGroupIds: contactGroupIds,
              contactGroupNames: contactGroupNames,
              groupId: groupId,
              //recipient: groupIds,
              schedule: combinedDateTime
                ? combinedDateTime
                : MessageDetails?.scheduledFor && MessageDetails?.scheduledFor,

              msgId: msgID || msgId,
              reset: reset(),
            },
          });
        }

        generateResult(
          "success",
          "Review your email",
          "Review your message before sending or check your drafts to edit the message"
        );
      } else {
        let newString: string = "";
        if (images.length > 0) {
          let newString = content;

          // Process each image in the images array
          for (const image of images) {
            const img = await handleEmail(image);

            // Replace each <img> tag with the processed image URL
            newString = newString.replace(
              /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
              `<img src="${img}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
            );
          }

          // Update any remaining content as necessary
          newString = newString.replace(/https:\/\//g, "http://");
          navigate("review", {
            state: {
              ...data,
              contactIds: contactIds,
              contactGroupIds: contactGroupIds,
              contactGroupNames: contactGroupNames,
              groupId: groupId,

              type: "email",
              content: newString,
              //recipient: groupIds,

              msgId: msgID || msgId,
            },
          });
          generateResult(
            "success",
            "Review your email",
            "Review your message before sending or check your drafts to edit the message"
          );
        } else {
          newString = content;
          navigate("review", {
            state: {
              ...data,
              contactIds: contactIds,
              contactGroupIds: contactGroupIds,
              contactGroupNames: contactGroupNames,
              groupId: groupId,

              type: "email",
              content: newString,
              //recipient: groupIds,

              msgId: msgID || msgId,
              reset: reset(),
            },
          });

          generateResult(
            "success",
            "Review your email",
            "Review your message before sending or check your drafts to edit the message"
          );
        }
      }
    } catch (error) {
      const e = parseClientError(error);
      generateResult("error", e.message || "An unexpected error occurred.");
    }
  };
  const { isTrue, toggle } = useToggle();

  function handleNewGroup() {
    if (!isTrue) {
      toggle();
    }
    setOpenGroup((prev) => !prev);
  }

  useEffect(() => {
    if (watch("recipient") && groups) {
      setRecipient(watch("recipient"));
      const recipient = watch("recipient");
      const id = [];
      id.push(recipient);
      setGroupsId(id);
      const ids = groups.docs.filter((doc) => doc._id !== recipient); // Filter out the object where _id equals recipient

      setCheckboxGroups(ids);
    }
  }, [groups, watch]);
  const selectedRecipient = watch("recipient");
  async function handleEmail(blob: Blob) {
    type Response = {
      secure_url: string;
    };
    if (blob) {
      const formData = new FormData();
      formData.append("media", blob);
      formData.append("type", "Message");
      const response: SuccessDataType<Response> = await uploadImage({
        token,
        requestOption: {
          method: "POST",
          body: formData,
        },
      });
      return response.data.secure_url;
    }
  }

  if (error) {
    const e = parseClientError(error);
    message.error(capitalize(e.message) || "Something went wrong");
  }
  useEffect(() => {
    if (MessageDetails) {
      setValue("msgTitle", MessageDetails.title);
      setValue("emailSubject", MessageDetails.subject);
      // setValue("date", dayjs(MessageDetails.scheduledFor).toISOString() || "");
      // setValue("time", dayjs(MessageDetails.scheduledFor).toISOString());
      setValue("from", MessageDetails.senderName);
      const groupId = groups?.docs.find(
        (group) => group.name === MessageDetails.nameOfContactGroups
      );
      setContent(MessageDetails.body || "");

      !loadGroups &&
        setValue(
          "recipient",
          typeof groupId?._id === "string" ? groupId?._id : ""
        );
    }
  }, [MessageDetails, groups?.docs, loadGroups, setValue]);
  useEffect(() => {
    if (MessageDetails?.body) {
      setContent(MessageDetails.body);
    }
  }, [MessageDetails?.body]);
  useEffect(() => {
    if (msgId) {
      setmsgId(msgId);
    }
  }, [msgId]);
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  return (
    <>
      <ReusableModal
        title="Schedule message"
        handleModal={handleNewGroup}
        isModalOpen={isMobile && openGroup}
        centered
      >
        <SetSchedule
          handleCombined={handleCombined}
          handleNewGroup={handleNewGroup}
          schedule={msgId ? MessageDetails?.scheduledFor : null}
        />
      </ReusableModal>
      <ReusableDrawer
        title="Schedule message"
        Open={!isMobile && openGroup}
        handleOpen={handleNewGroup}
      >
        <SetSchedule
          handleCombined={handleCombined}
          handleNewGroup={handleNewGroup}
          schedule={msgId ? MessageDetails?.scheduledFor : null}
        />
      </ReusableDrawer>
      {isLoading || error || loadGroups ? (
        <div className={s.spin}>
          <ThreeDots
            visible={true}
            height="50"
            width="50"
            color="#d52b1e"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              dispaly: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className={s.wrapper}>
          <div className={s.emailTitles}>
            <div className={s.msgTitleWrapper}>
              <h1 className={s.msgTitle}>
                {msgId && MessageDetails
                  ? MessageDetails.title
                  : watch("msgTitle") || "Untitled"}
              </h1>
            </div>
            <div className={s.inputWrapper}>
              <h5 className={s.label}>Message title</h5>
              <Controller
                name="msgTitle"
                control={control}
                rules={{ required: "Message title is required" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    defaultValue={
                      msgId && MessageDetails ? MessageDetails.title : ""
                    }
                    count={{
                      show: true,
                      max: 80,
                    }}
                    className={clsx(s.input, {
                      [s.inputError]: errors.msgTitle,
                    })}
                    maxLength={80}
                  />
                )}
              />
              {!errors.msgTitle && (
                <span className={s.helperText}>
                  <p className={s.titleMsg}>
                    This is just an internal title to help organize and locate
                    your message easily. This does not appear to your audience.
                  </p>
                </span>
              )}
              {errors.msgTitle && (
                <span className={clsx(s.errorMessage)}>
                  {errors.msgTitle.message}
                </span>
              )}
            </div>
            <div className={clsx(s.inputWrapper)}>
              <h5 className={s.label}>Email subject</h5>
              <Controller
                name="emailSubject"
                control={control}
                rules={{ required: "Subject is required" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    defaultValue={
                      msgId && MessageDetails ? MessageDetails.subject : ""
                    } // Option 1
                    count={{
                      show: true,
                      max: 100,
                    }}
                    className={clsx(s.input, {
                      [s.inputError]: errors.emailSubject,
                    })}
                    maxLength={100}
                  />
                )}
              />
              {errors.emailSubject && (
                <span className={clsx(s.errorMessage)}>
                  {errors.emailSubject.message}
                </span>
              )}
            </div>

            <div className={clsx(s.inputWrapper)}>
              <h5 className={s.label}>From</h5>
              <input
                type="text"
                style={{ padding: "12px" }}
                className={clsx(s.input, { [s.inputError]: errors.from })}
                {...register("from", { required: false })}
              />
              {errors.from && (
                <span className={clsx(s.errorMessage)}>
                  {errors.from.message}
                </span>
              )}
            </div>
            <div className={clsx(s.inputWrapper)}>
              <h5 className={s.label}>Reply email </h5>
              <input
                type="email"
                style={{ padding: "12px" }}
                placeholder="example@domain.com"
                defaultValue={cachedData.email}
                className={clsx(s.input, { [s.inputError]: errors.replyTo })}
                {...register("replyTo", { required: false })}
              />
              {errors.from && (
                <span className={clsx(s.errorMessage)}>
                  {errors.replyTo?.message}
                </span>
              )}
            </div>
            {!groupId && (
              <Recipients
                handleContact={handleContacts}
                handleGroup={handleGroups}
              />
            )}
          </div>

          <div className={s.textEditor}>
            <div className={s.editorBtnGroup}>
              <button
                type="button"
                className={clsx(s.scheduleBtn)}
                onClick={handleNewGroup}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              {/* For opening scheduling modal */}
              <button
                className={clsx(s.mobileScheduleBtn)}
                onClick={handleNewGroup}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              <button
                type="submit"
                //loading={isMutating || isSubmitting}
                className={clsx(s.submitBtn)}
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                <Oval
                  visible={isSubmitting}
                  height="16"
                  width="16"
                  color="white"
                  ariaLabel="oval-loading"
                />
                Send now
              </button>
            </div>
            <RichTextEditor
              handleImageUpload={handleImageUpload}
              value={content}
              handleEdit={handleEdit}
              modules={null}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Email;
