import React from "react";
import s from "./Details.module.scss";
import { Oval } from "react-loader-spinner";
type Prop = {
  date: string;
  creditUsed: string;
  loading: boolean;
  response: string;
  handleReset: () => void;
};
function Details({ creditUsed, date, loading, response, handleReset }: Prop) {
  if (loading)
    return (
      <div className={s.loaderWrapper}>
        <Oval
          visible={true}
          height="35"
          width="35"
          color="#dc4e44"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  return (
    <div className={s.mainWrapper}>
      <div className={s.wrapper}>
        <div className={s.main}>
          <div className={s.head}>
            <h5 className={s.title}>Date</h5>
            <p className={s.subtitle}>{date}</p>
          </div>
          <div className={s.head}>
            <h5 className={s.title}>Credit used</h5>
            <p className={s.subtitle}>${creditUsed}</p>
          </div>
        </div>
        <div className={s.responseTitle}>
          <h6>Cloudivi response</h6>
        </div>
        <div className={s.response}>
          <p>{response}</p>
        </div>
      </div>

      <div className={s.buttons}>
        <button onClick={handleReset} className={s.cancelBtn}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default Details;
