import clsx from "clsx";
import s from "./ResetCode.module.scss";
import React from "react";
import { Link,  useNavigate } from "react-router-dom";
import OtpInputs from "../../../global components/OtpInputs";
import { capitalize, useQuery } from "../../../libs/common";
import useSWRMutation from "swr/mutation";
import { generateResult } from "../../../global components/ResultModal/ResultModal";
import {
  parseClientError,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../libs/api/Endpoints";
import Navigation from "../../../global components/Navigation/Navigation";
import { SuccessDataType } from "../../../libs/api/types";
type otpResponse = {
  token: string;
};

function ResetCode() {
  const navigate = useNavigate();
  const query = useQuery();
  const userId = query.get("userId");
  const email = query.get("email");
  const { trigger: enterEmail } = useSWRMutation(
    "api/auth/forgot-password",
    swrMutationFetcher
  );
  const { trigger: verifyOTP, isMutating } = useSWRMutation(
    "api/auth/verify-otp",
    swrMutationFetcher
  );
  const handleResendOTP = async () => {
    const urlEncodedData = new URLSearchParams();
    if (email) urlEncodedData.append("userId", email);
    try {
      const fetchArg: SwrMutationFetcherArgType = {
        requestOption: {
          body: urlEncodedData,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      await enterEmail(fetchArg);

      generateResult("success", "Your One-Time Passcode (OTP) has been Sent");
    } catch (error) {
      const e = parseClientError(error);
      generateResult(
        "error",
        capitalize(e.message) || "An unexpected error occurred."
      );
    }
  };
  const handleOtpSubmit = async (otp: string) => {
    const urlEncodedData = new URLSearchParams();
    try {
      if (userId) urlEncodedData.append("userId", userId);
      urlEncodedData.append("otp", otp);
      urlEncodedData.append("type", "email");
      const fetchArg: SwrMutationFetcherArgType = {
        requestOption: {
          body: urlEncodedData,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      const response:SuccessDataType<otpResponse> = await verifyOTP(fetchArg);

      navigate("/auth/reset-password", {
        state: { token: response.data.token },
      });
      generateResult("success", "Reset your password");
    } catch (error) {
      const e = parseClientError(error);
      generateResult(
        "error",
        e.message || "An unexpected error occurred. Please try again later"
      );
    }
  };
  return (
    <div className={s.wrapper}>
      <div className={s.navigate}>
        <Navigation />
      </div>
      <div className={s.mainContent}>
        <span className={s.titles}>
          <h3 className={s.title}>Reset code</h3>
          <p className={s.subtitle}>
            Provide the code that was sent to {email}
          </p>
        </span>
        <div className={clsx(s.form)}>
          <OtpInputs
            numInputs={6} // Number of OTP inputs
            handleSubmit={handleOtpSubmit} // Your submit function
            otpLengthCheck={4} // Optional check for OTP length
            errorMessage="Please enter the complete OTP"
            buttonLabel="Continue"
            loading={isMutating}
            buttonClassName={s.button} // Pass custom button class if needed
          />
        </div>

        <div className={s.createAccount}>
          <p>
            Did not get the code? &nbsp;
            <span onClick={handleResendOTP} className={s.links}>
              Resend code
            </span>
            &nbsp; or &nbsp;
            <Link to="/auth/forgot-password" className={s.links}>
              Change email
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ResetCode;
