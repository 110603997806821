import React from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import Separator from "../../../../global components/Icons/Separator";
import BreadCrumb from "../../../../global components/BreadCrumbs/BreadCrumbs";
import s from "./InnerPage.module.scss";
import clsx from "clsx";

import MessageOne from "../../../../global components/Icons/MessageOne";
import { useQuery } from "../../../../libs/common";
function ViewEditor() {
  const { id } = useParams(); // Get email from URL params
  const query = useQuery();
  const keyword = query.get("keyword");
  const location = useLocation();
  const pathName = location.pathname;
  const items = [
    {
      title: (
        <Link to="/dashboard/smart-keywords" className={s.homeLink}>
          <MessageOne fill="#898EA9" width={15} height={15} /> Smart keyword
        </Link>
      ),
    },

    {
      title: (
        <Link
          className={clsx(pathName.includes(id!) && s.active)}
          to={`/smart-keywords/${id}`}
          onClick={(e) => {
            if (pathName.includes(id!)) e.preventDefault();
          }}
        >
          {keyword || ""}
        </Link>
      ), // Added title text
    },
  ];

  return (
    <div className={s.wrapper}>
      <div className={s.breadCrumb}>
        <BreadCrumb
          items={items}
          separator={<Separator height={11} width={6.67} fill="#9CA3AF" />}
        />
      </div>
      <h2 className={s.title}>{keyword || "N/A"}</h2>
      <Outlet />
    </div>
  );
}

export default ViewEditor;
