import React, { useRef, useState } from "react";
import s from "./Header.module.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import gsap from "gsap";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger, useGSAP);
function Header() {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState<boolean | null>(null);
  const mobileNavRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  const location = useLocation();
  const currentPath = location.pathname;

  useGSAP(() => {
    const tl = gsap.timeline();

    if (isMobile) {
      if (showMenu) {
        // Set display to block before opening animation
        tl.fromTo(
          mobileNavRef.current,
          { css: { backgroundColor: "none" } },
          {
            css: {
              backgroundImage: "linear-gradient(to top, #19082a, #0e0d0d)",
            },
          }
        );

        gsap.fromTo(
          mobileNavRef.current,
          { width: "100%", height: "52px" }, // Start state
          {
            width: "100%",
            height: "100vh",
            duration: 1, // Duration of animation
            ease: "power2.inOut", // Easing for smooth animation
          }
        );
      } else if (showMenu != null && !showMenu) {
        // Animate closing and set display to none after the animation
        gsap.to(mobileNavRef.current, {
          height: "52px",
          duration: 0.5, // Faster duration for closing
          ease: "power2.inOut",
          onComplete: () => {
            gsap.set(mobileNavRef.current, {
              backgroundColor: "none",
              backgroundImage: "none",
            });
          },
        });
      }
    }
  }, [isMobile, showMenu]);
  const handleNavigation = () => {
    if (currentPath === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth
      setShowMenu(false);
    } else {
      navigate("/");
      setShowMenu(false);
    }
  };
  return (
    <header ref={mobileNavRef} className={s.wrapper}>
      <div onClick={handleNavigation}>
        <img
          src={
            "https://res.cloudinary.com/duq2axbo3/image/upload/v1738403492/Cloudivi_white_uadlmi.png"
          }
          title="Cloudivi logo"
          alt="Cloudivi logo"
          className={s.logo}
        />
      </div>
      <div className={s.links}>
        <Link to={"/about-us"} onClick={() => setShowMenu(false)}>
          About Cloudivi
        </Link>
        {/* <a href="/#aboutSection"> </a> */}
        <NavLink
          to={currentPath !== "/" ? "/faq" : "/#faqSection"}
          onClick={() => setShowMenu(false)}
        >
          FAQs
        </NavLink>
      </div>
      <div className={clsx(s.navigations)}>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate("/auth/signin")}
          className={s.signinBtn}
        >
          Sign in
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate("/auth/signup")}
          className={s.registerBtn}
        >
          Get started now
        </motion.button>
      </div>

      <div className={s.hamburger} onClick={() => setShowMenu(!showMenu)}>
        <div className={clsx(s.content, { [s.active]: showMenu })}></div>
      </div>
    </header>
  );
}

export default Header;
