import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Separator from "../../../../global components/Icons/Separator";
import BreadCrumb from "../../../../global components/BreadCrumbs/BreadCrumbs";
import s from "./InnerPage.module.scss";
import clsx from "clsx";
import useDocumentMetadata from "../../../../global components/PageTitle";
function ViewDetails() {
  const location = useLocation();
  const pathName = location.pathname;
  const documentTitle = "Message | Message Details";
  const documentDescription =
    "Review message details, track delivery status, and manage communication history.";
    useDocumentMetadata(documentTitle, documentDescription);

  const items = [
    {
      title: <Link to="/dashboard/Message">Message</Link>,
    },

    {
      title: (
        <Link
          aria-disabled
          className={clsx(pathName.includes("message-details") && s.active)}
          to="/dashboard/Message/email"
        >
          Message details
        </Link>
      ), // Added title text
    },
  ];

  return (
    <div className={s.wrapper}>
      <div className={s.breadCrumb}>
        <BreadCrumb
          items={items}
          separator={<Separator height={11} width={6.67} fill="#9CA3AF" />}
        />
      </div>
      <Outlet />
    </div>
  );
}

export default ViewDetails;
