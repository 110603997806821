import React, { useEffect, useState } from "react";
import s from "./AddContactForm.module.scss";
import clsx from "clsx";
import { Button,  Spin } from "antd";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import useSWRMutation from "swr/mutation";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../libs/api/Endpoints";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import { UseAuthToken } from "../../../../libs/api/userSession";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import useSWR from "swr";
import Tags from "../../../../global components/Tags/Tags";
import { SingleContactResponse } from "../../../../libs/Utils/Types/singContact";
import { CustomTag } from "../../../../libs/Utils/Types/Tags";
import { useCountryCode } from "../../../../global components/Context/CountryCode";
import ConfirmationModal from "../../../../global components/CofirmationModal/ConfirmationModal";
import { capitalize } from "../../../../libs/common";
import Unauthorised from "../../../../global components/Unauthorised";
import { PaginatedContactGroups } from "../../../../libs/Utils/Types/ContactGroup";
type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
};

type EditContactProp = {
  handleOpen: () => void;
  contactID: string | null;
  mutate: () => void;
};

function EditContactForm({ handleOpen, mutate, contactID }: EditContactProp) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<Inputs>({ shouldFocusError: false });
  const token = UseAuthToken();
  const [groupId, setGroupId] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const { trigger: addContact } = useSWRMutation(
    `api/contacts/${contactID}`,
    swrMutationFetcher
  );
  const { countryCode } = useCountryCode();

  const {
    data: contact,
    isLoading,
    mutate: mutateContact,
    error,
  } = useSWR<SingleContactResponse, any, any>(
    token ? [`api/contacts/${contactID}`, token] : null,
    swrFetcher,

    {
      revalidateOnFocus: true, // Refetch when the window regains focus
      // Refetch every 10 seconds (10000 ms)
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );

  const {
    data: groups
   
  } = useSWR<PaginatedContactGroups, any, any>(
    token
      ? [
          "api/contact-groups",
          token,
        ]
      : null,
    swrFetcher,
    {
      //refreshInterval: 10000,

      revalidateOnFocus: true,
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    // const urlEncodedData = new URLSearchParams();
    // urlEncodedData.append("firstName", data.firstName);
    // urlEncodedData.append("lastName", data.lastName);
    // urlEncodedData.append("email", data.email);
    // urlEncodedData.append("password", data.phoneNo);
    // urlEncodedData.append("contactGroupIds", []);

    try {
      if (token) {
        const fetchArg: SwrMutationFetcherArgType = {
          token: token,
          requestOption: {
            method: "PATCH",
            body: JSON.stringify({
              ...(data.firstName && { firstName: data.firstName.trimEnd() }),
              ...(data.lastName && { lastName: data.lastName.trimEnd() }),
              ...(data.email && { email: data.email.trimEnd() }),
              ...(data.phoneNo && {
                phoneNo: data.phoneNo.includes("+")
                  ? data.phoneNo.trimEnd()
                  : `+${data.phoneNo.trimEnd()}`,
              }),
              contactGroupIds: groupId || [],
            }),
            headers: {
              "Content-Type": "application/json", // Set content type to JSON
            },
          },
        };
        await addContact(fetchArg);
        mutate();
        mutateContact();
        handleOpen();
        generateResult("success", "Contact saved");
        reset();
      }
    } catch (error: any) {
      const e = parseClientError(error);
      const errorMessage =
        capitalize(e.message) ||
        "An unexpected error occurred." ||
        "An unexpected error occurred.";
      generateResult("error", errorMessage);
      console.error(e);
    }
  };
  const [allTags, setAllTags] = useState<CustomTag[]>([]);
  const [newTag /*setNewTag*/] = useState<string[]>([]);

  useEffect(() => {
    if (contact?.groups) {
      const initialTags = contact.groups.map((group) => ({
        key: group.id,
        label: group.name + ` (${groups?.docs.find(item=>item._id===group.id)?.contacts||0})`,
      }));
      setAllTags(initialTags);
      setGroupId(initialTags.map((tag) => tag.key)); // Initialize groupId with existing group IDs
    }
  }, [contact, groups?.docs]);

  const handleTagsChange = (updatedTags: CustomTag[]) => {
    setAllTags(updatedTags);
    // Update groupId based on tags
    setGroupId(updatedTags.map((tag) => tag.key));
  };
  const handleConfirmationModal = () => {
    setOpen((prev) => !prev);
  };
  // if (error) {
  //   const e = parseClientError(error);
  //   message.error(capitalize(e.message) || "Something went wrong");
  // }
  useEffect(() => {
    if (contact) {
      if (contact.contact.email) setValue("email", contact.contact.email);
      if (contact.contact.firstName)
        setValue("firstName", contact.contact.firstName);
      if (contact.contact.lastName)
        setValue("lastName", contact.contact.lastName);
      if (contact.contact.phoneNo) setValue("phoneNo", contact.contact.phoneNo);
    }
  }, [contact, setValue]);
  return isLoading ? (
    <div className={s.spin}>
      <Spin />
    </div>
  ) : error ? (
    <div className={s.spin}>
      <Unauthorised
        error={{
          title: "Error",
          message:
            capitalize(parseClientError(error).message) ||
            "Something went wrong",
        }}
      />
    </div>
  ) : (
    <>
      <ConfirmationModal
        handleCancel={handleOpen}
        open={open}
        handleConfirmationModal={handleConfirmationModal}
      />
      <div className={s.wrapper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={s.forms}>
            <div className={clsx(s.inputWrapper)}>
              <label htmlFor="firstName" className={clsx(s.inputLabel)}>
                First name
              </label>
              <input
                id="firstName"
                defaultValue={contact?.contact.firstName}
                className={clsx({ [s.inputError]: errors.firstName })}
                {...register("firstName")}
              />
              {errors.firstName && (
                <span className={clsx(s.errorMessage)}>
                  {errors.firstName.message}
                </span>
              )}
            </div>
            <div className={clsx(s.inputWrapper)}>
              <label htmlFor="lastName" className={clsx(s.inputLabel)}>
                Last name
              </label>
              <input
                id="lastName"
                defaultValue={contact?.contact.lastName}
                className={clsx({ [s.inputError]: errors.lastName })}
                {...register("lastName")}
              />
            </div>

            <div className={clsx(s.inputWrapper)}>
              <label htmlFor="email" className={clsx(s.inputLabel)}>
                Email address
              </label>
              <input
                id="email"
                defaultValue={contact?.contact.email}
                className={clsx(s.input, { [s.inputError]: errors.email })}
                {...register("email")}
              />
            </div>

            <div className={clsx(s.inputWrapper)}>
              <label htmlFor="phoneNo" className={clsx(s.inputLabel)}>
                Phone number
              </label>
              <Controller
                name="phoneNo"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    country={countryCode.toLowerCase()} // default country
                    enableSearch={true} // Enable searching countries
                    placeholder="Enter phone number"
                    onChange={field.onChange} // directly update field value
                    containerClass={s.phoneInputContainer} // Added for custom CSS
                    dropdownClass={s.phoneInputDropdown}
                    inputClass={clsx(s.input, {
                      [s.inputError]: errors.phoneNo,
                    })} // Phone number input field class
                    buttonClass={s.flagDropdown} // Dropdown for flag and country code
                    value={contact?.contact.phoneNo}
                  />
                )}
              />
            </div>
            <div className={clsx(s.inputWrapper)}>
              <h5 className={clsx(s.inputLabel)}>Groups</h5>
              <div className={s.tags}>
                <Tags
                  initialTags={allTags}
                  newTag={newTag.length > 0 ? newTag[0] : undefined}
                  onTagsChange={handleTagsChange}
                />
              </div>
            </div>
          </div>
          <div className={s.addContactBtns}>
            <Button
              type="default"
              className={clsx(s.button, s.cancelBtn)}
              onClick={handleConfirmationModal}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className={clsx(s.button, s.addContactBtn)}
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Save changes
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditContactForm;
