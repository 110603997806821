import React, { useEffect, useState } from "react";
import s from "./SuggestedKeyword.module.scss";
import Wand from "../../../../../../global components/Icons/Wand";
import clsx from "clsx";
type Prop = {
  handleKeyword: (word: string | null) => void;
  clear: string | null;
};
function SuggestedKeyword({ handleKeyword, clear }: Prop) {
  const [active, setActive] = useState<string | null>(null);
  const keywords: string[] = [
    "Healthy living",
    "Housing",
    "Vacation",
    "Cloudivi",
  ];

  useEffect(() => {
    if (clear === null) {
      setActive(null); // Reset active when clear is triggered
    }
  }, [clear]);

  useEffect(() => {
    handleKeyword(active);
  }, [active, handleKeyword]);
  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className={s.title}>
          <Wand className={s.titleIcon} />
          <h6>Suggested smart keywords</h6>
        </div>
        <p className={s.subtitle}>
          We have identified few smart keywords that might get you started.
        </p>
      </div>
      <div className={s.keywords}>
        {keywords.map((items, index) => {
          return (
            <button
              key={index}
              onClick={() => setActive(items)}
              className={clsx(s.keywordBtn, { [s.active]: active === items })}
            >
              {items}
              <span
                className={clsx(s.radio, { [s.active]: active === items })}
              ></span>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default SuggestedKeyword;
