import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Separator from "../../../../global components/Icons/Separator";
import BreadCrumb from "../../../../global components/BreadCrumbs/BreadCrumbs";
import s from "./InnerPage.module.scss";
import Home from "../../../../global components/Icons/Home/Home";
import clsx from "clsx";
import useDocumentMetadata from "../../../../global components/PageTitle";
function InnerPage() {
 const documentTitle = "Contacts | Contact Details";
  const    documentDescription =
        "View and manage details of individual contacts, including messages and engagement history.";
        useDocumentMetadata(documentTitle, documentDescription);

  const location = useLocation();
  const pathName = location.pathname;
  const items = [
    {
      title: <Link to="/dashboard/Contacts">Contact</Link>,
      icon: <Home width={17} height={17} fill="#BEC2CA" />,
    },
    ...(location.pathname.includes("contact-details")
      ? [
          {
            title: (
              <Link
                className={clsx(
                  pathName.includes("contact-details") && s.active
                )}
                to="/dashboard/Contact/contact-details"
                onClick={(e) => {
                  if (pathName.includes("contact-details")) e.preventDefault();
                }}
              >
                Contact details
              </Link>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className={s.wrapper}>
      <div className={s.BreadCrumb}>
        <BreadCrumb
          items={items}
          separator={<Separator height={11} width={6.67} fill="#9CA3AF" />}
        />
      </div>
      <Outlet />
    </div>
  );
}

export default InnerPage;
