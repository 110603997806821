import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import s from "./Auth.module.scss";
import clsx from "clsx";
import logo from "../../assets/images/Cloudivi white.webp";
import { useTypeBoxes } from "./GsapAnimation";
import logoWhite from "../../assets/images/Cloudivi white.webp";
function Auth() {
  const { TypeBoxes, typeboxWrapperRef } = useTypeBoxes();
  const navigate = useNavigate();
  return (
    <div className={clsx(s.wrapper)}>
      <span  onClick={() => navigate("/")} className={s.logoWhite}>
        <img
         
          src={logoWhite}
          className={s.logo}
          alt="logoWhite"
        />
      </span>
      <div className={clsx(s.main)}>
        <div className={clsx(s.mainBody)}>
          <img
            onClick={() => navigate("/")}
            src={logo}
            className={clsx(s.logo)}
            alt="cloudivo logo"
          />
          <div className={clsx(s.typeboxWrapper)} ref={typeboxWrapperRef}>
            {TypeBoxes}
          </div>
        </div>
      </div>

      <div className={clsx(s.auth)}>
        <Outlet />
      </div>
    </div>
  );
}

export default Auth;
