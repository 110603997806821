import React from "react";
import s from "./ResentKeywords.module.scss";
import { ColumnType } from "antd/es/table";
import dayjs from "dayjs";
import clsx from "clsx";
import { PaginatedKeywords } from "../../../../../../libs/Utils/Types/SmartKeywords";
import {
  parseClientError,
} from "../../../../../../libs/api/Endpoints";
import Keywords from "../../../../../../global components/Icons/Keywords";
import NoData from "../../../../../../global components/Table/NoData/NoData";
import Unauthorised from "../../../../../../global components/Unauthorised";
import { capitalize } from "../../../../../../libs/common";
import ReusableTable from "../../../../../../global components/Table/Table";
import { useMediaQuery } from "react-responsive";
interface DataType {
  key: string;
  keyword: string;
  status: string;
  hitCount: number;
  createdAt: string;
}
type Props={
  keywords:PaginatedKeywords | undefined
  isLoading:boolean,
  error:Error
}
function ResentKeywords({keywords,isLoading,error}:Props) {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  
  const columns: ColumnType<DataType>[] = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      render: (keyword: string) => <span>{keyword}</span>,
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => (
        <span>{dayjs(createdAt).format("MMM D, YYYY")}</span>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span className={clsx(s.statusCell, s[status.toLowerCase()])}>
          {status}
        </span>
      ),
    },
    {
      title: "Hit count",
      dataIndex: "hitCount",
      key: "hitCount",
      render: (hitCount: string) => (
        <span className={clsx(s.cell)}>{hitCount}</span>
      ),
    },
  ];
  //   const transformedData = keywords?.docs.map((contact, index) => ({
  //     key: contact._id,
  //     hitCount: contact.hits.length,

  //     keyword: contact.keyword,
  //     createdAt: contact.createdAt,

  //     status: "",
  //   }));

  const transformedData = keywords?.docs.map((contact) => ({
    key: contact.id,
    hitCount: contact.hitCount,

    keyword: contact.keyword,
    createdAt: contact.createdAt,

    status: contact.status,
  }));
  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>Recently created keyword</h3>
      <div className={s.rTable}>
        <ReusableTable
          loading={isLoading}
          sticky={{ offsetHeader: 0 }}
          scroll={{ y: 270,x:"max-content" }}
          noDataComponent={
            error ? (
              <Unauthorised
                error={{
                  title: "Error",
                  message:
                    capitalize(parseClientError(error).message) ||
                    "Something went wrong",
                }}
              />
            ) : (
              <NoData
                Message="No smart keywords found"
                subMessage="Get started by creating a smart keyword"
                icon={
                  <Keywords
                    fill="#F0EFEE"
                    height={isMobile ? 32 : 60}
                    width={isMobile ? 32 : 80}
                  />
                }
              />
            )
          }
          dataSource={transformedData || []}
          columns={columns}
        />
      </div>
    </div>
  );
}

export default ResentKeywords;
